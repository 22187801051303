import React, { useEffect, useState } from "react";
import axios from "axios";
import Image from "antd/lib/image";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
const contract_fetch_url = "https://fapi.binance.com/fapi/v1/exchangeInfo";
const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
const ForecastingPage = ({ fc_data_receiver = undefined, set_fc_data_receiver = () => {}, webSocket = undefined, ml_types = [], set_ml_types = () => {}, timeframes = [] }) => {
  const [time_step, set_time_step] = useState({
    type: "Months",
    step: 1,
  });
  const [time_step_config, set_time_step_config] = useState({
    Days: {
      type: "Days",
      max: 30,
      min: 1,
      "4h": (val) => {
        let res = Math.floor((val * 24) / 4);
        return res;
      },
      "1d": (val) => {
        let res = Math.floor(val / 1);
        return res;
      },
      "1w": (val) => {
        let res = Math.floor((val * 7) / 1);
        return res;
      },
      "1mo": (val) => {
        let res = Math.floor((val * 30) / 1);
        return res;
      },
    },
    Months: {
      type: "Months",
      max: 12,
      min: 1,
      "4h": (val) => {
        let res = Math.floor((val * 730) / 4);
        return res;
      },
      "1d": (val) => {
        let res = Math.floor((val * 30) / 1);
        return res;
      },
      "1w": (val) => {
        let res = Math.floor((val * 4) / 1);
        return res;
      },
      "1mo": (val) => {
        let res = Math.floor(val / 1);
        return res;
      },
    },
    Years: {
      type: "Years",
      max: 5,
      min: 1,
      "4h": (val) => {
        let res = Math.floor((val * 8760) / 4);
        return res;
      },
      "1d": (val) => {
        let res = Math.floor((val * 365) / 1);
        return res;
      },
      "1w": (val) => {
        let res = Math.floor((val * 52) / 1);
        return res;
      },
      "1mo": (val) => {
        let res = Math.floor((val * 12) / 1);
        return res;
      },
    },
  });
  const [image_viewer, set_image_viewer] = useState(undefined);
  const [dialog_data, set_dialog_data] = useState(undefined);
  const [symbol_list, set_symbol_list] = useState([]);
  const [symbol, set_symbol] = useState("");
  const [start_date, set_start_date] = useState(undefined);
  const [end_date, set_end_date] = useState(undefined);
  const [timeframe, set_timeframe] = useState(undefined);
  const [generating, set_generating] = useState(undefined);
  const generate_prediction = (ml_type) => {
    set_generating(ml_type.id);
    console.log("ml_type", ml_type, webSocket.current["general"], start_date?.valueOf() || undefined);
    if (symbol == "") {
      //show dialog error
      set_dialog_data({
        title: "Missing Value",
        msg: "Please select the asset you want to forecast.",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
          set_generating(undefined);
        },
      });
      return;
    }
    if (start_date && end_date) {
      if (end_date.valueOf() <= start_date.valueOf()) {
        //show dialog error
        set_dialog_data({
          title: "Invalid Value",
          msg: "Invalid date range. End date cannot be lower than the start date",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
            set_generating(undefined);
          },
        });
        return;
      }
    }
    if (timeframe === undefined) {
      //show dialog error
      set_dialog_data({
        title: "Missing Value",
        msg: "Please select a timeframe for the forecast.",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
          set_generating(undefined);
        },
      });
      return;
    }
    let timestep = { ...time_step };
    let cond = time_step_config[timestep.type];
    let step = timestep.step * 1;
    if (cond == undefined) {
      set_dialog_data({
        title: "Invalid Value",
        msg: "Please select a valid time step type.",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
          set_generating(undefined);
        },
      });
      return;
    }
    console.log("step", step, cond);
    if (step < cond.min || step > cond.max) {
      set_dialog_data({
        title: "Invalid Value",
        msg: "Please input a valid time step value.",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
          set_generating(undefined);
        },
      });
      return;
    }
    let timeStep = cond[timeframe](step);
    if (webSocket.current["general"]) {
      webSocket.current["general"].send(
        JSON.stringify({
          type: "generate_ml_predictions",
          ml_type: ml_type.id,
          symbol: symbol,
          startdate: start_date == undefined || end_date == undefined ? undefined : start_date?.valueOf() || undefined,
          enddate: start_date == undefined || end_date == undefined ? undefined : end_date?.valueOf() || undefined,
          symbol: symbol,
          timeframe: timeframe,
          timeStep: timeStep,
        })
      );
    } else {
      set_generating(undefined);
    }
  };
  useEffect(() => {
    get_contracts();
  }, []);
  const get_contracts = () => {
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .get(
        contract_fetch_url,
        {},
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response?.data?.symbols) {
          let temp = [];
          for (let i = 0; i < response?.data?.symbols.length; i++) {
            const symbol = response?.data?.symbols[i];
            if (temp.includes(symbol.pair)) {
            } else {
              temp.push(symbol.pair);
            }
          }
          set_symbol_list(temp);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const data_receiver = (data) => {
    if (data?.status == "ok") {
      setTimeout(() => {
        set_generating(undefined);
      }, 1500);
    } else {
      set_dialog_data({
        title: "Forecasting Error",
        msg: data?.msg || "",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
          set_generating(undefined);
        },
      });
    }
  };
  useEffect(() => {
    if (fc_data_receiver) {
      data_receiver(fc_data_receiver);
      set_fc_data_receiver(undefined);
    }
  }, [fc_data_receiver]);
  return [
    <Dialog
      open={dialog_data !== undefined}
      onClose={
        dialog_data?.cancel_cb
          ? dialog_data?.cancel_cb
          : () => {
              set_dialog_data(undefined);
            }
      }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialog_data?.title || ""}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialog_data?.msg || ""}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={
            dialog_data?.cancel_cb
              ? dialog_data?.cancel_cb
              : () => {
                  set_dialog_data(undefined);
                }
          }
          autoFocus
        >
          {dialog_data?.type === "confirmation" ? "Go Back" : "Close"}
        </Button>
        {dialog_data?.type === "confirmation" ? (
          <Button
            onClick={() => {
              set_dialog_data(undefined);
              dialog_data?.cb();
            }}
          >
            Leave
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>,
    <div style={{ maxHeight: "100%", maxWidth: "100%", overflow: "auto" }}>
      <div className="forecasting-option-container" style={{ display: "flex", flexDirection: "row", alignItems: "center", maxWidth: "100%", paddingBottom: 12, overflowX: "auto" }}>
        <div className="forecasting-option" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <p style={{ marginRight: 8, fontSize: "0.875rem" }}>ASSET</p>
          <Autocomplete
            disabled={generating !== undefined}
            style={{ minWidth: 250 }}
            size="small"
            options={symbol_list}
            onChange={(e, value) => {
              set_symbol(value);
            }}
            value={symbol}
            renderInput={(params) => <TextField {...params} label="Asset.." />}
          />
        </div>
        <div className="forecasting-option" style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 32 }}>
          <div style={{ marginRight: 12, fontSize: "0.875rem", textWrap: "nowrap" }}>DATE RANGE</div>

          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", minWidth: 250, marginRight: 8 }}>
            <p style={{ marginRight: 8, fontSize: "0.875rem" }}>From</p>
            <DatePicker disabled={generating !== undefined} disableFuture className="sm-datepicker" value={start_date} onChange={(val) => set_start_date(val)} />
          </div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", minWidth: 250 }}>
            <p style={{ marginRight: 8, fontSize: "0.875rem" }}>To</p>
            <DatePicker disabled={generating !== undefined} className="sm-datepicker" disableFuture value={end_date} onChange={(val) => set_end_date(val)} />
          </div>
        </div>
        <div className="forecasting-option" style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 32 }}>
          <TextField
            onChange={(e) => {
              let value = e.target.value;

              let temp = { ...time_step };
              let cond = time_step_config[temp.type];

              temp.step = Math.floor(value * 1);
              if (temp.step <= cond.min) {
                temp.step = cond.min;
              }
              if (temp.step >= cond.max) {
                temp.step = cond.max;
              }
              set_time_step(temp);
            }}
            value={time_step.step}
            style={{ marginRight: 8, width: 75 }}
            size="small"
            type="number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          />
          <Autocomplete
            disabled={generating !== undefined}
            style={{ minWidth: 150, borderLeft: 0 }}
            size="small"
            options={Object.keys(time_step_config)}
            onChange={(e, value) => {
              let temp = { ...time_step };

              temp.type = value;
              let cond = time_step_config[temp.type];
              if (temp.step <= cond.min) {
                temp.step = cond.min;
              }
              if (temp.step >= cond.max) {
                temp.step = cond.max;
              }
              set_time_step(temp);
            }}
            value={time_step.type}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
        <div className="forecasting-option" style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 32 }}>
          {["4h", "1d", "1w", "1mo"].map((tf, index) => {
            return [
              <Button
                disabled={tf == timeframe || generating !== undefined}
                onClick={() => {
                  set_timeframe(tf);
                }}
                size="small"
                style={{ textTransform: "lowercase" }}
                label={tf}
                value={tf}
              >
                {tf}
              </Button>,
            ];
          })}
        </div>
      </div>
      <Divider style={{ marginTop: 8 }} />
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", height: "100%", marginTop: 16 }}>
        {ml_types.map((ml, index) => {
          return [
            <div className="forecasting-model" style={{ minWidth: 100, textAlign: "center" }} key={ml.id}>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <div color="inherit">{ml.description}</div>
                  </React.Fragment>
                }
              >
                <Button
                  disabled={generating !== undefined}
                  onClick={() => {
                    generate_prediction(ml);
                  }}
                  variant="outlined"
                  style={{ width: "100%" }}
                >
                  {ml.title}
                  {generating == ml.id ? <div style={{ marginLeft: 8 }} className="loader"></div> : null}
                </Button>
              </HtmlTooltip>
              <div></div>
              <div style={{ display: "flex", flexDirection: "column", minHeight: 64, padding: 16 }}>
                <Image.PreviewGroup>
                  {ml.predictions ? (
                    <Image
                      onClick={() => {
                        let images = [];
                        if (ml.predictions) images.push(ml.predictions);
                        if (ml.positions) images.push(ml.positions);
                        if (ml.test_data) images.push(ml.test_data);
                        set_image_viewer({
                          index: 0,
                          images: images,
                        });
                      }}
                      src={ml.predictions}
                      style={{ minWidth: 350, minHeight: 350 }}
                    />
                  ) : null}
                  <div style={{ marginBottom: 16 }}></div>
                  {ml.positions ? (
                    <Image
                      onClick={() => {
                        let images = [];
                        if (ml.predictions) images.push(ml.predictions);
                        if (ml.positions) images.push(ml.positions);
                        if (ml.test_data) images.push(ml.test_data);
                        set_image_viewer({
                          index: 1,
                          images: images,
                        });
                      }}
                      src={ml.positions}
                      style={{ minWidth: 350, minHeight: 350 }}
                    />
                  ) : null}
                  <div style={{ marginBottom: 16 }}></div>
                  {ml.test_data ? (
                    <Image
                      onClick={() => {
                        let images = [];
                        if (ml.predictions) images.push(ml.predictions);
                        if (ml.positions) images.push(ml.positions);
                        if (ml.test_data) images.push(ml.test_data);
                        set_image_viewer({
                          index: 2,
                          images: images,
                        });
                      }}
                      src={ml.test_data}
                      style={{ minWidth: 350, minHeight: 350 }}
                    />
                  ) : null}
                </Image.PreviewGroup>

                {/* Trading Position

                    "Go Long" if > 0
                    "Go Short" if < 0 */}
              </div>
            </div>,
          ];
        })}
      </div>
    </div>,
  ];
};

export default ForecastingPage;

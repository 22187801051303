import React, { useState } from "react";
import SessionChecker from "../modules/inc/session-checker";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { api_url } from "../../keys";
const Login = (props) => {
  const navigate = useNavigate();
  const [dialog_data, set_dialog_data] = useState(undefined);
  const [username, set_username] = useState("");
  const [password, set_password] = useState("");
  const [valid_username, set_valid_username] = useState(true);
  const [valid_password, set_valid_password] = useState(true);
  const [submitting, set_submitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const submitLogin = () => {
    set_submitting(true);
    const username_temp = (username || "").trim();
    if (username_temp === "") {
      set_dialog_data({
        title: "Missing Credential",
        msg: "Please provide your username and try again.",
      });
      set_valid_username(false);
      set_submitting(false);
      return;
    } else set_valid_username(true);
    if (password === "") {
      set_dialog_data({
        title: "Missing Credential",
        msg: "Please provide your password and try again.",
      });
      set_valid_password(false);
      set_submitting(false);
      return;
    } else set_valid_password(true);

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        api_url + "/login/",
        { username: username_temp, password },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          //continue
          sessionStorage.setItem("user_data", JSON.stringify(res.data.data));
          sessionStorage.setItem("session_timeout", Date.now());
          navigate("/", {
            state: {},
          });
        } else {
          set_dialog_data({
            title: "Authentication Error",
            msg: res.data.msg,
          });
          set_submitting(false);
        }
      })
      .catch((err) => {
        set_submitting(false);
        set_dialog_data({
          title: "Request Error",
          msg: "Something went wrong, Please try again later.",
        });
      });
  };
  return [
    <SessionChecker />,
    <Dialog
      open={dialog_data !== undefined}
      onClose={() => {
        set_dialog_data(undefined);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialog_data?.title || ""}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialog_data?.msg || ""}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            set_dialog_data(undefined);
          }}
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>,
    <div className="centered-component" style={{ minHeight: "100vh" }}>
      <Card sx={{ minWidth: 200, maxWidth: 300, boxShadow: "rgba(135, 135, 135, 0.3) 2px 2px 5px, rgba(135, 135, 135, 0.22) 0px 5px 5px" }}>
        <CardContent>
          <h2 style={{ margin: 0 }}>Login</h2>
          <TextField
            autoFocus
            size="small"
            onKeyDown={(ev) => {
              if (ev.key === "Enter") {
                submitLogin();
                ev.preventDefault();
              }
            }}
            // style={{ maxWidth: 275 }}
            error={!valid_username}
            value={username}
            onChange={(e) => {
              set_username(e.target.value);
            }}
            disabled={submitting}
            label="Username"
            margin="normal"
            fullWidth
          />
          <TextField
            size="small"
            // style={{ maxWidth: 275 }}
            onKeyDown={(ev) => {
              if (ev.key === "Enter") {
                submitLogin();
                ev.preventDefault();
              }
            }}
            error={!valid_password}
            value={password}
            onChange={(e) => {
              set_password(e.target.value);
            }}
            disabled={submitting}
            type={showPassword ? "text" : "password"}
            label="Password"
            margin="normal"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", marginTop: 16 }}>
            <LoadingButton
              size="small"
              onClick={() => {
                submitLogin();
              }}
              loadingPosition="end"
              loading={submitting}
              variant="contained"
              endIcon={<ArrowForwardIosIcon />}
            >
              <span>Login</span>
            </LoadingButton>
          </div>
        </CardContent>
      </Card>
    </div>,
  ];
};

export default Login;

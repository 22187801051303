import React, { useEffect, useRef, useCallback } from "react";
import { component_bg_color } from "../../keys";
import debounce from "lodash/debounce";
let tvScriptLoadingPromise;

export default function TradingViewWidget({ pair, exchange }) {
  const onLoadScriptRef = useRef();
  const generate_graph = () => {
    if (pair[exchange] !== "" && exchange !== "") {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement("script");
          script.id = "tradingview-widget-loading-script";
          script.src = "https://s3.tradingview.com/tv.js";
          script.type = "text/javascript";
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => (onLoadScriptRef.current = null);

      function createWidget() {
        if (document.getElementById("tradingview_9d689") && "TradingView" in window) {
          let tradeview = new window.TradingView.widget({
            autosize: true,
            symbol: (exchange + ":" + pair[exchange]).toUpperCase() || "BINANCE:BTCUSDT",
            interval: "15",
            timezone: "Asia/Manila",
            theme: "dark",
            style: "1",
            locale: "en",
            toolbar_bg: "#f1f3f6",
            enable_publishing: false,
            allow_symbol_change: true,
            hide_side_toolbar: false,

            backgroundColor: "#141720", //14161f
            // studies: ["ROC@tv-basicstudies", "StochasticRSI@tv-basicstudies", "MASimple@tv-basicstudies"],
            container_id: "tradingview_9d689",
          });
          console.log("tradeview", tradeview);
        }
      }
    }
  };
  const change_watchlist_tf_debounce = useCallback(debounce(generate_graph, 250), [pair, exchange]);
  useEffect(() => {
    if (pair[exchange] === "" || exchange === "") {
      let el = document.getElementById("tradeview-empty-container");
      if (el) {
        if (RegExp("div", "i").test(el.childNodes[0].tagName)) {
          el.childNodes[0].style.display = "none";
        }
      }
    }
    change_watchlist_tf_debounce();
  }, [pair[exchange], exchange]);

  return (
    <div className="tradingview-widget-container" style={{ height: "60vh" }}>
      {pair[exchange] === "" || exchange === "" ? (
        <div
          id="tradeview-empty-container"
          style={{
            backgroundColor: component_bg_color,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Select {exchange === "" ? "An Exchange" : "A Pair"}
        </div>
      ) : (
        <div id="tradingview_9d689" style={{ height: "100%" }} />
      )}
    </div>
  );
}

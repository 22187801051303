import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import numeral from "numeral";
const timeframe_equiv = {
  "1m": 1,
  "3m": 3,
  "5m": 5,
  "15m": 15,
  "30m": 30,
  "1h": 60,
  "2h": 60 * 2,
  "4h": 60 * 4,
  "6h": 60 * 6,
  "8h": 60 * 8,
  "12h": 60 * 12,
};
const rows = [{ id: 1, exchange: "Binance", strategy: "Hello", pair: "World", timeframe: "World", total_transaction: 10, wins: 7, losses: 3, win_rate: 70 }];

const columns = [
  { minWidth: 100, headerAlign: "center", align: "center", field: "exchange", headerName: "Exchange" },
  { minWidth: 100, headerAlign: "center", align: "center", field: "strategy", headerName: "Bot Strategy" },
  { minWidth: 100, headerAlign: "center", align: "center", field: "pair", headerName: "Pair" },
  {
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    field: "timeframe",
    headerName: "Timeframe",
    valueGetter: (props) => {
      const { hasFocus, value, row } = props;

      return timeframe_equiv[row.timeframe];
    },
    renderCell: (props) => {
      const { hasFocus, value, row } = props;

      return <span>{row.timeframe}</span>;
    },
  },
  { minWidth: 100, headerAlign: "center", align: "center", field: "total", headerName: "Total Transaction" },
  { minWidth: 100, headerAlign: "center", align: "center", field: "wins", headerName: "Wins" },
  { minWidth: 100, headerAlign: "center", align: "center", field: "losses", headerName: "Losses" },
  {
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    field: "win_rate",
    headerName: "Win Rate",
    valueGetter: (props) => {
      const { hasFocus, value, row } = props;
      let wins = (row.wins || 0) * 1;
      let total = (row.total || 0) * 1;
      let win_rate = wins === 0 ? 0 : (wins / total) * 100;
      return win_rate;
    },
    renderCell: (props) => {
      const { hasFocus, value, row } = props;
      let wins = (row.wins || 0) * 1;
      let total = (row.total || 0) * 1;
      let win_rate = wins === 0 ? 0 : (wins / total) * 100;
      return [
        <div style={{ textAlign: "center" }}>
          <div>{numeral(win_rate).format("0,0[.][00]")} %</div>
        </div>,
      ];
    },
  },
];

const ExchangeWinRateTable = ({ analytics_data = [], strategy_data = [] }) => {
  return (
    <div style={{ height: analytics_data.length === 0 ? 200 : 350, width: "fit-content" }}>
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        hideFooter={analytics_data.length > 10 ? false : true}
        className="positiontables"
        rows={(analytics_data || []).map((row, ind) => {
          return {
            id: row.exchange + "_" + row.timeframe + "_" + row.strategy + "_" + row.pair,
            ...row,
          };
        })}
        columns={columns}
      />
    </div>
  );
};

export default ExchangeWinRateTable;

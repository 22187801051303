import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import numeral from "numeral";
const columns = [
  { flex: 1, minWidth: 100, headerAlign: "center", align: "center", field: "strategy", headerName: "Bot Strategy" },
  { flex: 1, minWidth: 100, headerAlign: "center", align: "center", field: "pair", headerName: "Pair" },
  { flex: 1, minWidth: 100, headerAlign: "center", align: "center", field: "timeframe", headerName: "Timeframe" },
  { flex: 1, minWidth: 100, headerAlign: "center", align: "center", field: "sl_pct", headerName: "Risk (SL)" },
  {
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    field: "risk_hit",
    headerName: "Hit",
    valueGetter: (props) => {
      const { hasFocus, value, row } = props;
      let losses = (row.losses || 0) * 1;
      let total = (row.total || 0) * 1;
      let rate = losses === 0 ? 0 : (losses / total) * 100;
      return rate;
    },
    renderCell: (props) => {
      const { hasFocus, value, row } = props;
      let losses = (row.losses || 0) * 1;
      let total = (row.total || 0) * 1;
      let rate = losses === 0 ? 0 : (losses / total) * 100;
      return [
        <div style={{ textAlign: "center" }}>
          <div>
            {numeral(losses).format("0,0[.][00]")} / {numeral(total).format("0,0[.][00]")}
          </div>
        </div>,
      ];
    },
  },
  {
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    field: "risk_hit_rate",
    headerName: "Hit Rate",
    valueGetter: (props) => {
      const { hasFocus, value, row } = props;
      let losses = (row.losses || 0) * 1;
      let total = (row.total || 0) * 1;
      let rate = losses === 0 ? 0 : (losses / total) * 100;
      return rate;
    },
    renderCell: (props) => {
      const { hasFocus, value, row } = props;
      let losses = (row.losses || 0) * 1;
      let total = (row.total || 0) * 1;
      let rate = losses === 0 ? 0 : (losses / total) * 100;
      return [
        <div style={{ textAlign: "center" }}>
          <div>{numeral(rate).format("0,0[.][00]")} %</div>
        </div>,
      ];
    },
  },
  { flex: 1, minWidth: 100, headerAlign: "center", align: "center", field: "tp_pct", headerName: "Reward (TP)" },
  {
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    field: "reward_hit",
    headerName: "Hit",
    valueGetter: (props) => {
      const { hasFocus, value, row } = props;
      let wins = (row.wins || 0) * 1;
      let total = (row.total || 0) * 1;
      let rate = wins === 0 ? 0 : (wins / total) * 100;
      return rate;
    },
    renderCell: (props) => {
      const { hasFocus, value, row } = props;
      let wins = (row.wins || 0) * 1;
      let total = (row.total || 0) * 1;
      let rate = wins === 0 ? 0 : (wins / total) * 100;
      return [
        <div style={{ textAlign: "center" }}>
          <div>
            {numeral(wins).format("0,0[.][00]")} / {numeral(total).format("0,0[.][00]")}
          </div>
        </div>,
      ];
    },
  },
  {
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    field: "reward_hit_rate",
    headerName: "Hit Rate",
    valueGetter: (props) => {
      const { hasFocus, value, row } = props;
      let wins = (row.wins || 0) * 1;
      let total = (row.total || 0) * 1;
      let rate = wins === 0 ? 0 : (wins / total) * 100;
      return rate;
    },
    renderCell: (props) => {
      const { hasFocus, value, row } = props;
      let wins = (row.wins || 0) * 1;
      let total = (row.total || 0) * 1;
      let rate = wins === 0 ? 0 : (wins / total) * 100;
      return [
        <div style={{ textAlign: "center" }}>
          <div>{numeral(rate).format("0,0[.][00]")} %</div>
        </div>,
      ];
    },
  },
];

const StrategyWinRateTable = ({ analytics_data = [], strategy_data = [] }) => {
  return (
    <div style={{ height: strategy_data.length === 0 ? 200 : 350, width: "100%" }}>
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        hideFooter={strategy_data.length > 10 ? false : true}
        className="positiontables"
        rows={strategy_data.map((row, ind) => {
          return {
            id: row.exchange + "_" + row.timeframe + "_" + row.strategy + "_" + row.pair,
            ...row,
          };
        })}
        columns={columns}
      />
    </div>
  );
};

export default StrategyWinRateTable;

module.exports = {
  // in_prod: false,
  // api_url: "http://localhost:8000/api",
  // channel_ip: "ws://localhost:8000",

  in_prod: true,
  api_url: "https://kurotbot.betkodigital.com/be/api",
  channel_ip: "wss://kurotbot.betkodigital.com",

  component_bg_color: "#141720",
  header_color: "#14161f",
  header_border_color: "#2f3545",
  green: "#31bd60",
  red: "#bd3131",
  yellow: "#cca629",
  session_valid_for: 1, //in day/s
  minute_ms: 60000,
  websocket_keys: [
    "ml_predictions_response",
    "timeframe_update",
    "running_bots",
    "strategy_bot_started",
    "stop_bot",
    "testing_type",
    "update_ui",
    "watchlist_new",
    "watchlist_update",
    "account_balance",
    "is_tradable_data_update",
    "initiate_strategy_bot",
    "strategy_bot_error",
  ],
};

import React from "react";
import Home from "./pages/home";
import Login from "./pages/login";
import { in_prod } from "../keys";
import { BrowserRouter as Router, HashRouter, Routes, Route } from "react-router-dom";

const Main = (props) => {
  const routes = (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/:id/:name" element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="*" element={"No Page"} />
    </Routes>
  );
  return [<Router basename="/">{routes}</Router>];
};

export default Main;

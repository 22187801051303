import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled, alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import LockIcon from "@mui/icons-material/Lock";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
const Header = ({ screenwidth, active_page = "trading", set_active_page = () => {}, open_change_password = false, set_open_change_password = () => {}, open_user_settings = false, set_open_user_settings = () => {}, logout = () => {} }) => {
  const [open_menu, set_open_menu] = useState(null);
  const handle_open_menu = (e) => {
    set_open_menu(e.currentTarget);
  };
  const handle_close_menu = () => {
    set_open_menu(null);
  };
  return [
    <AppBar component="nav" style={{ backgroundColor: "#141720", backgroundImage: "none" }}>
      <Toolbar style={{ minHeight: 0 }}>
        {/* <AdbIcon sx={{ display: { xs: "flex", md: "flex" }, mr: 1 }} /> */}

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} className="PageTab">
          <Tabs
            variant="scrollable"
            scrollButtons={"auto"}
            allowScrollButtonsMobile
            value={active_page}
            style={{ minHeight: 36.5 }}
            onChange={(e, page) => {
              set_active_page(page);
            }}
            aria-label="PageTabs"
          >
            {["trading", "forecasting"].map((page) => (
              <Tab key={page} label={page} value={page} className={"dense-tabs"} style={{ textTransform: "capitalize" }} sx={{ color: "white", display: "block" }}>
                {page}
              </Tab>
            ))}
          </Tabs>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Button
              onClick={handle_open_menu}
              style={{ minHeight: 36.5, lineHeight: "normal", color: "white", fontSize: screenwidth < 900 ? ".7rem" : undefined, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
            >
              User Settings
            </Button>
            {screenwidth < 900 ? null : (
              <Button
                style={{ opacity: 0, cursor: "default", minHeight: 36.5, lineHeight: "normal", color: "white", fontSize: screenwidth < 900 ? ".7rem" : undefined, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
              >
                User Settings
              </Button>
            )}
          </div>
          <StyledMenu
            anchorEl={open_menu}
            open={Boolean(open_menu)}
            onClose={handle_close_menu}
            sx={{ mt: "8px" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {/* <MenuItem
              onClick={() => {
                set_open_user_settings(true);
                handle_close_menu();
              }}
            >
              <SettingsIcon />
              <Typography>Setting</Typography>
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                set_open_change_password(true);
                handle_close_menu();
              }}
            >
              <LockIcon />
              <Typography>Change Password</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                logout();
                handle_close_menu();
              }}
            >
              <LogoutIcon />
              <Typography>Logout</Typography>
            </MenuItem>
          </StyledMenu>
        </Box>
      </Toolbar>
    </AppBar>,
    <Toolbar style={{ minHeight: 36 }} />, //to offset the fixed header
  ];
};

export default Header;

export default {
  Technical: (
    <div style={{ padding: 8 }}>
      <h3>Technical Strategy</h3>
      <div>Indicators:</div>
      <div>
        <ul>
          <li>RSI(Relative Strength Index)</li>
          <li>MACD(Moving Average Convergence/Divergence)</li>
        </ul>
      </div>

      <div style={{ marginTop: 8 }}></div>
      <div>Additional Parameters:</div>
      <div>
        <ul>
          <li>EMA Fast</li>
          <li>EMA Slow</li>
          <li>EMA Signal</li>
          <li>RSI Length</li>
        </ul>
      </div>
    </div>
  ),
  Breakout: (
    <div style={{ padding: 8 }}>
      <h3>Breakout Strategy</h3>
      <div>Indicators:</div>
      <div>
        <ul>
          <li>Latest Closed Price</li>
          <li>Previous High Price</li>
          <li>Previous Low Price</li>
        </ul>
      </div>

      <div style={{ marginTop: 8 }}></div>
      <div>Additional Parameter:</div>
      <div>
        <ul>
          <li>None</li>
        </ul>
      </div>
    </div>
  ),
  Trend: (
    <div style={{ padding: 8 }}>
      <h3>Trend Strategy</h3>
      <div>Indicators:</div>
      <div>
        <ul>
          <li>General Trend Analysis</li>
          <li>Instant Trend Analysis</li>
          <li>RSI(Relative Strength Index)</li>
          <li>Stochastic Analysic</li>
        </ul>
      </div>

      <div style={{ marginTop: 8 }}></div>
      <div>Additional Parameter:</div>
      <div>
        <ul>
          <li>None</li>
        </ul>
      </div>
    </div>
  ),
  Scalping: (
    <div style={{ padding: 8 }}>
      <h3>Scalping Strategy</h3>
      <div>Indicators:</div>
      <div>
        <ul>
          <li>Closed Price</li>
          <li>Moving Average Fast</li>
          <li>Moving Aerage Slow</li>
        </ul>
      </div>

      <div style={{ marginTop: 8 }}></div>
      <div>Additional Parameter:</div>
      <div>
        <ul>
          <li>MA Fast</li>
          <li>MA Slow</li>
        </ul>
      </div>
    </div>
  ),
  LongShort: (
    <div style={{ padding: 8 }}>
      <h3>Long/Short Strategy</h3>
      <div>Indicators:</div>
      <div>
        <ul>
          <li>Closed Price</li>
          <li>Volume</li>
          <li>Returns</li>
          <li>Volume Change</li>
        </ul>
      </div>

      <div style={{ marginTop: 8 }}></div>
      <div>Additional Parameter:</div>
      <div>
        <ul>
          <li>None</li>
        </ul>
      </div>
    </div>
  ),
};

import React, { useState, useEffect, useRef, useCallback } from "react";
import debounce from "lodash/debounce";
// import { useSearchParams } from "react-router-dom";
import Header from "../modules/header";
import ExchangeList from "../modules/exchange_list";
import Grid from "@mui/material/Grid";
import Side from "../modules/side";
import TradingViewWidget from "../modules/trading_view_widget";
import PositionTabs from "../modules/positions_tabs";
import ChannelManager from "../data-channel/channel-manager";
import SessionChecker from "../modules/inc/session-checker";
import MountableDrawer from "../reusable-components/MountableDrawer";
import StrategyBoard from "../modules/sub-module/StrategyBoard";
import StrategyTooltip from "../modules/inc/strategy-tooltip";
import { useNavigate, useLocation } from "react-router-dom";
import SettingDrawer from "../modules/SettingDrawer";
import PasswordDrawer from "../modules/PasswordDrawer";
import ForecastingPage from "../modules/forecasting-page";
const Home = (props) => {
  const [screenwidth, setscreenwidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setscreenwidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const webSocket = useRef({});
  const [active_page, set_active_page] = useState("trading"); //"trading" or "forecasting" will default to "trading" if no appropriate value
  const [user_data, set_user_data] = useState(undefined);
  const [account_balance, set_account_balance] = useState(0);
  const [is_tradable_data, set_is_tradable_data] = useState({});
  // const [params] = useSearchParams();
  const [ml_types, set_ml_types] = useState([
    {
      id: "lstm",
      title: "LSTM",
      description: "Long Short-Term Memory",
      positions: undefined, //img
      forecast: undefined, //img
    },
    {
      id: "svm",
      title: "SVM",
      description: "Support Vector Machine",
      positions: undefined, //img
      forecast: undefined, //img
    },
    {
      id: "var",
      title: "VAR",
      description: "Vector Autoregressive Model",
      positions: undefined, //img
      forecast: undefined, //img
    },
    {
      id: "hmm",
      title: "HMM",
      description: "Hidden Markov Model",
      positions: undefined, //img
      forecast: undefined, //img
    },
  ]);
  const [timeframes, set_timeframes] = useState(["1m", "3m", "5m", "15m", "30m", "1h", "2h", "4h", "6h", "8h", "12h"]); // "1d", "3d", "1w", "1M"

  const [exchange, set_exchange] = useState("");
  const [exchange_list, set_exchange_list] = useState(["Kucoin", "Binance", "ByBit", "Kraken", "CoinBase", "OKX", "BitGet", "BingX"]);

  const [selected_strategy, set_selected_strategy] = useState(undefined);
  const [strategy_pair, set_strategy_pair] = useState("");
  const [strategy_timeframe, set_strategy_timeframe] = useState("5m");
  const [ss_tf, set_ss_tf] = useState("5m");
  const [size, set_size] = useState(0);
  const [tp_values, set_tp_values] = useState({
    value: 1,
    type: "Mark",
  });
  const [sl_values, set_sl_values] = useState({
    value: 1,
    type: "Mark",
  });

  const [strategies, set_strategies] = useState([
    {
      id: "Technical",
      name: "Technical Strategy",
      tooltip: StrategyTooltip["Technical"],
    },
    {
      id: "LongShort",
      name: "Long/Short Strategy",
      tooltip: StrategyTooltip["LongShort"],
    },
    {
      id: "Breakout",
      name: "Breakout Strategy",
      tooltip: StrategyTooltip["Breakout"],
    },
    {
      id: "Trend",
      name: "Trend Strategy",
      tooltip: StrategyTooltip["Trend"],
    },
    {
      id: "Scalping",
      name: "Scalping Strategy",
      tooltip: StrategyTooltip["Scalping"],
    },
  ]);
  const [open_additional_params, set_open_additional_params] = useState(undefined);
  const [ma_fast, set_ma_fast] = useState(5);
  const [ma_slow, set_ma_slow] = useState(20);

  const [ema_fast, set_ema_fast] = useState(12);
  const [ema_slow, set_ema_slow] = useState(26);
  const [ema_signal, set_ema_signal] = useState(9);
  const [rsi_length, set_rsi_length] = useState(50);

  const [pair, set_pair] = useState({
    KuCoin: "",
    Binance: "",
    ByBit: "",
    // "Kraken" : "",
    // "CoinBase" : "",
    // // "OKX" : "",
    BitGet: "",
    BingX: "",
  });
  const [pair_list, set_pair_list] = useState([]);

  const [pairwatchlist, set_pairwatchlist] = useState([]);
  const [open_strat_selection, set_open_strat_selection] = useState(false);

  const [open_user_settings, set_open_user_settings] = useState(false);
  const [open_change_password, set_open_change_password] = useState(false);

  const [active_tab, set_active_tab] = React.useState("watchlist"); //bot || watchlist

  const [open_position_list, set_open_position_list] = useState([]);
  const [fc_data_receiver, set_fc_data_receiver] = useState(undefined);
  const [watchlist_updating, set_watchlist_updating] = useState(true);
  const change_watchlist_tf = (timeframe) => {
    set_is_tradable_data({});
    webSocket.current[exchange].send(
      JSON.stringify({
        type: "timeframe_update",
        timeframe: timeframe,
      })
    );
  };
  const change_watchlist_tf_debounce = useCallback(debounce(change_watchlist_tf, 1000), [webSocket, exchange]);

  const get_exchange_list = () => {
    set_exchange_list([
      "KuCoin",
      "Binance",
      "ByBit",
      // "Kraken",
      // "CoinBase",
      // // "OKX",
      "BitGet",
      "BingX",
    ]);
  };

  useEffect(() => {
    // set_pair("");
    set_pair_list([]);
    set_pairwatchlist([]);
    set_active_tab("watchlist");
    set_watchlist_updating(true);
    // set_pair("AXSUSDT");
  }, [exchange]);
  // useEffect(() => {
  //   console.log("params", params.get(""));
  // }, [params]);
  useEffect(() => {
    get_exchange_list();
  }, []);

  const return_data = (data) => {
    set_user_data(data);
  };
  const channel_data_receiver = (data) => {
    const type = data?.type;
    const channel_data = data?.data;
    const channel_exchange = data?.exchange;
    if (type === "contract_list") {
      if (exchange != channel_exchange) return;

      set_pair_list(channel_data || []);
    } else if (type == "running_bots") {
      if (exchange != channel_exchange) return;
      set_open_position_list(channel_data?.bots || []);
    } else if (type === "watchlist_update") {
      if (exchange != channel_exchange) return;
      const price_data = channel_data;

      const list = price_data.concat(pairwatchlist);
      const result = list.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
      set_pairwatchlist(result);
      set_watchlist_updating(false);
    } else if (type === "account_balance") {
      if (exchange != channel_exchange) return;
      set_account_balance(parseFloat(channel_data));
    } else if (type === "is_tradable_data_update") {
      if (exchange != channel_exchange) return;
      let temp = { ...is_tradable_data };
      temp[channel_data["id"]] = { ...(temp[channel_data["id"]] || {}), ...(channel_data || {}) };
      set_is_tradable_data(temp);
    } else if (type === "strategy_bot_error") {
      let temp = [...open_position_list];
      temp = temp.filter((row) => row.id !== channel_data?.id);
      set_open_position_list(temp);
    } else if (type === "new_trade_position") {
      set_open_position_list(
        open_position_list.map((row, ind) => {
          let tp = 0;
          let sl = 0;
          if (channel_data?.entry && row?.reward) {
            if (row?.action === "long") {
              tp = channel_data?.entry * (1 + (row?.reward * 1) / 100);
            } else if (row?.action === "short") {
              tp = channel_data?.entry * (1 - (row?.reward * 1) / 100);
            }
          }
          if (channel_data?.entry && row?.risk) {
            if (row?.action === "long") {
              sl = channel_data?.entry * (1 - (row?.risk * 1) / 100);
            } else if (row?.action === "short") {
              sl = channel_data?.entry * (1 + (row?.risk * 1) / 100);
            }
          }
          return row.id === channel_data?.id
            ? channel_data?.status == "closed"
              ? {
                  id: row?.id,
                  opentime: row?.opentime,
                  status: "waiting",
                  risk: row?.risk,
                  reward: row?.reward,
                  pair: row?.pair,
                  exchange: row?.exchange,
                  strategy: row?.strategy,
                  timeframe: row?.timeframe,
                }
              : { ...row, ...channel_data, ...{ sl, tp } }
            : row;
        })
      );
    } else if (type === "strategy_bot_started") {
      set_open_position_list(
        open_position_list.map((row, ind) => {
          return row.id === channel_data?.id ? { ...row, ...{ status: "checking" } } : row;
        })
      );
    } else if (type === "ml_predictions_response") {
      set_fc_data_receiver(channel_data);
      console.log(channel_data, ml_types);
      if (channel_data?.status == "ok") {
        set_ml_types((prev) => {
          for (let i = 0; i < prev.length; i++) {
            let ml = prev[i];
            if (ml.id == channel_data?.ml_type) {
              ml.predictions = channel_data?.predictions ? "data:image/png;base64," + channel_data?.predictions : undefined;
              ml.positions = channel_data?.positions ? "data:image/png;base64," + channel_data?.positions : undefined;
              ml.test_data = channel_data?.test_data ? "data:image/png;base64," + channel_data?.test_data : undefined;
              break;
            }
          }
          return prev;
        });
      }
    }
  };
  const logout = () => {
    sessionStorage.removeItem("user_data");
    sessionStorage.removeItem("session_timeout");
    if (location.pathname === "/login") {
      return;
    }
    navigate("/login", {
      state: {
        test_params: "test_params",
      },
    });
  };
  const sideComponents = (
    <Side
      watchlist_updating={watchlist_updating}
      set_watchlist_updating={set_watchlist_updating}
      active_tab={active_tab}
      set_active_tab={set_active_tab}
      open_additional_params={open_additional_params}
      set_open_additional_params={set_open_additional_params}
      ma_fast={ma_fast}
      set_ma_fast={set_ma_fast}
      ma_slow={ma_slow}
      set_ma_slow={set_ma_slow}
      ema_fast={ema_fast}
      set_ema_fast={set_ema_fast}
      ema_slow={ema_slow}
      set_ema_slow={set_ema_slow}
      ema_signal={ema_signal}
      set_ema_signal={set_ema_signal}
      rsi_length={rsi_length}
      set_rsi_length={set_rsi_length}
      open_position_list={open_position_list}
      set_open_position_list={set_open_position_list}
      is_tradable_data={is_tradable_data}
      strategies={strategies}
      strategy_pair={strategy_pair}
      set_strategy_pair={set_strategy_pair}
      strategy_timeframe={strategy_timeframe}
      set_strategy_timeframe={set_strategy_timeframe}
      size={size}
      set_size={set_size}
      tp_values={tp_values}
      set_tp_values={set_tp_values}
      sl_values={sl_values}
      set_sl_values={set_sl_values}
      selected_strategy={selected_strategy}
      set_selected_strategy={set_selected_strategy}
      account_balance={account_balance}
      open_strat_selection={open_strat_selection}
      set_open_strat_selection={set_open_strat_selection}
      webSocket={webSocket}
      pair_list={pair_list}
      pair={pair}
      set_pair={set_pair}
      exchange={exchange}
      pairwatchlist={pairwatchlist}
      set_pairwatchlist={set_pairwatchlist}
    />
  );
  const positionTables = <PositionTabs exchange={exchange} webSocket={webSocket} set_open_position_list={open_position_list} open_position_list={open_position_list} exchange_list={exchange_list} />;
  return [
    <SessionChecker return_data={return_data} />,
    <SettingDrawer open_user_settings={open_user_settings} set_open_user_settings={set_open_user_settings} />,
    <PasswordDrawer open_change_password={open_change_password} set_open_change_password={set_open_change_password} />,
    <Header
      screenwidth={screenwidth}
      active_page={active_page}
      set_active_page={set_active_page}
      logout={logout}
      open_user_settings={open_user_settings}
      set_open_user_settings={set_open_user_settings}
      open_change_password={open_change_password}
      set_open_change_password={set_open_change_password}
    />,
    <ChannelManager user_data={user_data} exchange={exchange} receiver={channel_data_receiver} webSocket={webSocket} />,
    <div className="main-body-content">
      {active_page == "forecasting" ? (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <MountableDrawer show={active_page == "forecasting"} closable={false}>
            <ForecastingPage fc_data_receiver={fc_data_receiver} set_fc_data_receiver={set_fc_data_receiver} webSocket={webSocket} ml_types={ml_types} set_ml_types={set_ml_types} timeframes={timeframes} />
          </MountableDrawer>
        </div>
      ) : (
        <Grid container spacing={2} style={{ padding: 16, position: "relative" }}>
          <Grid item xs={12} sm={12} md={10} style={{ position: "relative" }}>
            {open_strat_selection ? (
              <MountableDrawer
                onClose={() => {
                  set_open_strat_selection(false);
                }}
                show={open_strat_selection}
                closable={false}
                open_strat_selection={open_strat_selection}
                set_open_strat_selection={set_open_strat_selection}
              >
                <StrategyBoard
                  exchange={exchange}
                  screenwidth={screenwidth}
                  active_tab={active_tab}
                  set_active_tab={set_active_tab}
                  open_additional_params={open_additional_params}
                  set_open_additional_params={set_open_additional_params}
                  set_pair={set_pair}
                  ss_tf={ss_tf}
                  set_ss_tf={change_watchlist_tf_debounce}
                  open_strat_selection={open_strat_selection}
                  set_open_strat_selection={set_open_strat_selection}
                  is_tradable_data={is_tradable_data}
                  strategy_pair={strategy_pair}
                  set_strategy_pair={set_strategy_pair}
                  strategy_timeframe={strategy_timeframe}
                  set_strategy_timeframe={set_strategy_timeframe}
                  size={size}
                  set_size={set_size}
                  tp_values={tp_values}
                  set_tp_values={set_tp_values}
                  sl_values={sl_values}
                  set_sl_values={set_sl_values}
                  selected_strategy={selected_strategy}
                  set_selected_strategy={set_selected_strategy}
                  strategies={strategies}
                  pairwatchlist={pairwatchlist}
                  timeframes={timeframes}
                  set_timeframes={set_timeframes}
                />
              </MountableDrawer>
            ) : null}

            <ExchangeList screenwidth={screenwidth} set_open_user_settings={set_open_user_settings} exchange={exchange} set_exchange={set_exchange} exchange_list={exchange_list} set_exchange_list={set_exchange_list} />
            <TradingViewWidget pair={pair} exchange={exchange} />
            <div style={{ marginBottom: 16 }}></div>
            {screenwidth < 900 ? sideComponents : positionTables}
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            {screenwidth < 900 ? positionTables : sideComponents}
          </Grid>
        </Grid>
      )}
    </div>,
  ];
};

export default Home;

import React, { useRef, useEffect } from "react";
import Paper from "@mui/material/Paper";
import BotStategy from "./sub-module/side/BotStrategy";
import WatchList from "./sub-module/side/WatchList";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import numeral from "numeral";
import Button from "@mui/material/Button";
const Side = ({
  watchlist_updating = false,
  set_watchlist_updating = () => {},
  open_position_list = [],
  set_open_position_list = () => {},
  is_tradable_data = {},
  strategies = [],
  strategy_pair = "",
  set_strategy_pair = () => {},
  strategy_timeframe = "",
  set_strategy_timeframe = () => {},
  size = 0,
  set_size = () => {},
  tp_values = {},
  set_tp_values = () => {},
  sl_values = {},
  set_sl_values = () => {},
  selected_strategy,
  set_selected_strategy = () => {},
  account_balance = 0,
  open_strat_selection = false,
  set_open_strat_selection = () => {},
  webSocket,
  pair_list = [],
  pair = "",
  set_pair = () => {},
  exchange = "",
  pairwatchlist = [],
  set_pairwatchlist = () => {},

  ma_fast = 5,
  set_ma_fast = () => {},
  ma_slow = 20,
  set_ma_slow = () => {},

  ema_fast = 12,
  set_ema_fast = () => {},
  ema_slow = 26,
  set_ema_slow = () => {},
  ema_signal = 9,
  set_ema_signal = () => {},
  rsi_length = 50,
  set_rsi_length = () => {},
  open_additional_params = undefined,
  set_open_additional_params = () => {},
  active_tab = "watchlist",
  set_active_tab = () => {},
}) => {
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open_additional_params) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open_additional_params]);
  const handleClose = () => {
    set_open_additional_params(undefined);
  };

  return [
    <Dialog open={open_additional_params != undefined} onClose={handleClose} scroll={"paper"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
      <DialogTitle id="scroll-dialog-title">Strategy Additional Paramaters</DialogTitle>
      <DialogContent dividers={"paper"}>
        {open_additional_params == "Technical" ? (
          <DialogContentText ref={descriptionElementRef} tabIndex={-1} style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              label="EMA Fast"
              fullWidth
              type="number"
              margin="normal"
              size="small"
              variant="outlined"
              value={ema_fast}
              onChange={(e) => {
                let value = e.target.value;

                value = value.replace(/-/g, "").replace(/e/g, "");

                let val = numeral(parseFloat(value)).format("0[.]00") * 1;
                if (val < 0) {
                  val = 0;
                }
                set_ema_fast(val);
              }}
            />
            <TextField
              label="EMA Slow"
              fullWidth
              type="number"
              margin="normal"
              size="small"
              variant="outlined"
              value={ema_slow}
              onChange={(e) => {
                let value = e.target.value;

                value = value.replace(/-/g, "").replace(/e/g, "");

                let val = numeral(parseFloat(value)).format("0[.]00") * 1;
                if (val < 0) {
                  val = 0;
                }
                set_ema_slow(val);
              }}
            />
            <TextField
              label="EMA Signal"
              fullWidth
              type="number"
              margin="normal"
              size="small"
              variant="outlined"
              value={ema_signal}
              onChange={(e) => {
                let value = e.target.value;

                value = value.replace(/-/g, "").replace(/e/g, "");

                let val = numeral(parseFloat(value)).format("0[.]00") * 1;
                if (val < 0) {
                  val = 0;
                }
                set_ema_signal(val);
              }}
            />
            <TextField
              label="RSI Length"
              fullWidth
              type="number"
              margin="normal"
              size="small"
              variant="outlined"
              value={rsi_length}
              onChange={(e) => {
                let value = e.target.value;

                value = value.replace(/-/g, "").replace(/e/g, "");

                let val = numeral(parseFloat(value)).format("0[.]00") * 1;
                if (val < 0) {
                  val = 0;
                }
                set_rsi_length(val);
              }}
            />
          </DialogContentText>
        ) : open_additional_params == "LongShort" ? (
          <DialogContentText ref={descriptionElementRef} tabIndex={-1} style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              label="MA Fast"
              fullWidth
              type="number"
              margin="normal"
              size="small"
              variant="outlined"
              value={ma_fast}
              onChange={(e) => {
                let value = e.target.value;

                value = value.replace(/-/g, "").replace(/e/g, "");

                let val = numeral(parseFloat(value)).format("0[.]00") * 1;
                if (val < 0) {
                  val = 0;
                }
                set_ma_fast(val);
              }}
            />
            <TextField
              label="MA Slow"
              fullWidth
              type="number"
              margin="normal"
              size="small"
              variant="outlined"
              value={ma_slow}
              onChange={(e) => {
                let value = e.target.value;

                value = value.replace(/-/g, "").replace(/e/g, "");

                let val = numeral(parseFloat(value)).format("0[.]00") * 1;
                if (val < 0) {
                  val = 0;
                }
                set_ma_slow(val);
              }}
            />
          </DialogContentText>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>,
    <Paper
      sx={{
        display: "flex",
        border: (theme) => `1px solid ${theme.palette.divider}`,
        flexWrap: "wrap",
        borderRadius: 0,
        backgroundColor: "#141720",
        backgroundImage: "none",
      }}
      // elevation={1}
    >
      {exchange === "" ? (
        <div style={{ minHeight: "75vh", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>Select An Exchange</div>
      ) : (
        <React.Fragment>
          <div className="custom-tab-container" style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "stretch" }}>
            <button disabled={open_strat_selection} onClick={() => set_active_tab("watchlist")} className={`button custom-tab-button ${active_tab == "watchlist" ? "custom-tab-button-active" : ""}`}>
              WATCHLIST
            </button>
            <button onClick={() => set_active_tab("bot")} className={`button custom-tab-button ${active_tab == "bot" ? "custom-tab-button-active" : ""}`}>
              BOT STRATEGY
            </button>
          </div>
          {active_tab == "bot" ? (
            <BotStategy
              set_active_tab={set_active_tab}
              open_additional_params={open_additional_params}
              set_open_additional_params={set_open_additional_params}
              ma_fast={ma_fast}
              set_ma_fast={set_ma_fast}
              ma_slow={ma_slow}
              set_ma_slow={set_ma_slow}
              ema_fast={ema_fast}
              set_ema_fast={set_ema_fast}
              ema_slow={ema_slow}
              set_ema_slow={set_ema_slow}
              ema_signal={ema_signal}
              set_ema_signal={set_ema_signal}
              rsi_length={rsi_length}
              set_rsi_length={set_rsi_length}
              open_position_list={open_position_list}
              set_open_position_list={set_open_position_list}
              webSocket={webSocket}
              strategies={strategies}
              strategy_pair={strategy_pair}
              set_strategy_pair={set_strategy_pair}
              strategy_timeframe={strategy_timeframe}
              set_strategy_timeframe={set_strategy_timeframe}
              size={size}
              set_size={set_size}
              tp_values={tp_values}
              set_tp_values={set_tp_values}
              sl_values={sl_values}
              set_sl_values={set_sl_values}
              selected_strategy={selected_strategy}
              set_selected_strategy={set_selected_strategy}
              account_balance={account_balance}
              pair={pair}
              exchange={exchange}
              open_strat_selection={open_strat_selection}
              set_open_strat_selection={set_open_strat_selection}
            />
          ) : active_tab == "watchlist" ? (
            <WatchList
              watchlist_updating={watchlist_updating}
              set_watchlist_updating={set_watchlist_updating}
              is_tradable_data={is_tradable_data}
              set_pair={set_pair}
              webSocket={webSocket}
              pair_list={pair_list}
              pair={pair}
              exchange={exchange}
              pairwatchlist={pairwatchlist}
              set_pairwatchlist={set_pairwatchlist}
            />
          ) : null}
        </React.Fragment>
      )}
    </Paper>,
  ];
};

export default Side;

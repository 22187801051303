import React, { useEffect, useState } from "react";
import moment from "moment";
import numeral from "numeral";
import axios from "axios";
import { api_url, green, red } from "../../../keys";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloseTable from "./CloseTable";
import CloseIcon from "@mui/icons-material/Close";
const PNLWeeklyTable = ({ total_pl = 0, set_total_pl = () => {}, total_investment = 0, set_total_investment = () => {}, exchange = "All", loading = false, set_loading = () => {} }) => {
  const [selected_month, set_selected_month] = useState(moment());
  const [calendar_template, set_calendar_template] = useState([]);

  const createCalendar = (year, month) => {
    let arr = [];
    let mon = month;
    let d = new Date(year, mon);

    for (let i = 0; i < getDay(d); i++) {
      arr.push(-1);
    }
    while (d.getMonth() == mon) {
      arr.push(d.getDate());
      if (getDay(d) % 7 == 6) {
        // sunday, last day of week - newline
      }

      d.setDate(d.getDate() + 1);
    }
    if (getDay(d) != 0) {
      for (let i = getDay(d); i < 7; i++) {
        arr.push(-1);
      }
    }
    let week_no = Math.ceil(arr.length / 7);
    let month_days = Array(week_no);
    for (let i = 0; i < month_days.length; i++) {
      let week_day_index = 0;
      let start_index = 7 * i;
      let week_data = [];
      for (let day = start_index; day < start_index + 7; day++) {
        const date = arr[day];
        week_data.push(date);
      }
      month_days[i] = week_data;
    }
    return month_days;
  };
  const getDay = (date) => {
    let day = date.getDay();
    if (day == 0) day = 7;
    return day - 1;
  };

  const [dialog_data, set_dialog_data] = useState(undefined);
  const [fetching_data, set_fetching_data] = useState(false);

  const get_analytics_data = (exchange, date) => {
    set_fetching_data(true);
    let user_data = sessionStorage.getItem("user_data");
    if (user_data === null || user_data === "") {
      user_data = undefined;
    }
    user_data = user_data ? JSON.parse(user_data) : {};
    let page_size = 10;
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        api_url + "/get_pnl/",
        {
          login_token: user_data.login_token,
          username: user_data.username,
          exchange: exchange === "All" ? undefined : exchange,
          type: "day",
          start_date: date.startOf("month").format("YYYY-MM-DD"),
          end_date: date.endOf("month").format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          //continue
          successful_saving(res?.data?.data);
          set_fetching_data(false);
        } else {
          console.log("error?", res.data);
          set_fetching_data(false);
          set_dialog_data({
            title: "Request Error",
            msg: "Something went wrong, Please try again later.",
            type: "alert",
            cancel_cb: () => {
              set_dialog_data(undefined);
            },
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        set_fetching_data(false);
        set_dialog_data({
          title: "Request Error",
          msg: "Something went wrong, Please try again later.",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
        });
      });
  };
  const successful_saving = (data) => {
    set_fetching_data(false);
    return_data(data);
  };
  const [daily_data_param, set_daily_data_param] = useState(undefined);

  const [pnl_data, set_pnl_data] = useState([]);
  const return_data = (data) => {
    if (data) {
      set_pnl_data(data);
      let t_investment = 0;
      let t_pl = 0;
      for (let i = 0; i < data.length; i++) {
        const row = data[i];
        t_investment += row.total_amount;
        let final_value = row.total_amount + row.total_pnl;
        t_pl += final_value;
      }
      set_total_investment(t_investment);
      set_total_pl(t_pl);
    }
  };
  useEffect(() => {
    if (exchange && selected_month.isValid()) {
      set_calendar_template(createCalendar(selected_month.year(), selected_month.month()));
      get_analytics_data(exchange, selected_month);
    }

    return () => set_pnl_data([]);
  }, [exchange, selected_month]);
  const generate_calendar = () => {
    let calendar_year = selected_month.year();
    let calendar_month = selected_month.month() + 1;
    let total_investment = 0;
    let over_all_total_pnl = 0;
    let total_roe = 0;
    let total_percentage = 0;
    const template = (
      <div style={{ overflow: "auto" }}>
        <table className="calendar">
          <thead>
            <tr>
              <th className="week-column">Week</th>
              <th>Monday</th>
              <th>Tuesday</th>
              <th>Wednesday</th>
              <th>Thursday</th>
              <th>Friday</th>
              <th>Saturday</th>
              <th>Sunday</th>
              <th>Total</th>
              <th>ROE</th>
              <th>Percentage</th>
            </tr>
          </thead>
          {calendar_template.map((week, week_index) => {
            let week_no = week_index + 1;
            let weekly_investment = 0;
            let weekly_pnl = 0;
            let weekly_roe = 0;
            let weekly_percentage = 0;
            return [
              <tr>
                <td className="week-column">{week_no}</td>
                {week.map((day, day_index) => {
                  console.log("day", day);
                  let data = pnl_data.filter((row) => {
                    let correct_year = calendar_year == row.year;
                    let correct_month = calendar_month == row.month;
                    let correct_day = day == row.day;
                    return correct_year && correct_month && correct_day;
                  });
                  data = data.length != 0 ? data[0] : {};
                  let total_pnl = data?.total_pnl || 0;
                  let total_amount = data?.total_amount || 0;
                  let gain = total_pnl > 0 ? true : false;

                  weekly_investment += total_amount;
                  weekly_pnl += total_amount + total_pnl;
                  weekly_roe += total_pnl;
                  weekly_percentage = weekly_roe == 0 || weekly_investment == 0 ? 0 : (weekly_roe / weekly_investment) * 100;

                  if (week.length == day_index * 1 + 1) {
                    total_investment += weekly_investment;
                    over_all_total_pnl += weekly_investment + weekly_roe;
                    total_roe += weekly_roe;
                    total_percentage = total_roe == 0 || total_investment == 0 ? 0 : (total_roe / total_investment) * 100;
                  }

                  return [
                    <td>
                      {day === -1 ? null : (
                        <a
                          className="button"
                          onClick={
                            data?.date
                              ? () => {
                                  let d = data?.date;
                                  set_daily_data_param(undefined);
                                  setTimeout(() => {
                                    set_daily_data_param({
                                      date: moment(d),
                                      week_no,
                                    });
                                  }, 250);
                                }
                              : undefined
                          }
                          style={{ cursor: data?.date ? undefined : "default", backgroundColor: "transparent", color: "white", display: "flex", minHeight: 75, width: "100%", flexDirection: "column", justifyContent: "space-between" }}
                        >
                          {/* {moment(`${calendar_month}-${day}-${calendar_year}`).format("MM-DD-YYYY")} */}
                          <div style={{ fontWeight: "bold" }}>{day}</div>
                          {total_amount === 0 ? (
                            <div></div>
                          ) : (
                            <div>
                              <span>{numeral(total_amount).format("0,0[.][00]")}</span>
                              <span> | </span>
                              <span style={{ color: total_pnl === 0 ? undefined : gain ? green : red }}>
                                {gain ? "+" : ""}
                                {numeral(total_pnl).format("0,0[.][00]")}
                              </span>
                            </div>
                          )}
                        </a>
                      )}
                    </td>,
                  ];
                })}
                <td className="week-column">
                  <div style={{ display: "flex", minHeight: 75, width: "100%", flexDirection: "column", justifyContent: "space-between" }}>
                    <div></div>
                    <div>
                      <span>{numeral(weekly_investment).format("0,0[.][00]")}</span>
                      <span> | </span>
                      <span style={{ color: weekly_pnl === 0 ? undefined : weekly_roe > 0 ? green : red }}>
                        {weekly_pnl === 0 ? "" : weekly_roe > 0 ? "+" : "-"}
                        {numeral(weekly_pnl).format("0,0[.][00]")}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="week-column">
                  <div style={{ display: "flex", minHeight: 75, width: "100%", flexDirection: "column", justifyContent: "space-between" }}>
                    <div></div>
                    <div style={{ color: weekly_roe === 0 ? undefined : weekly_roe > 0 ? green : red }}>
                      {weekly_roe > 0 ? "+" : ""}
                      {numeral(weekly_roe).format("0,0[.][00]")}
                    </div>
                  </div>
                </td>
                <td className="week-column">
                  <div style={{ display: "flex", minHeight: 75, width: "100%", flexDirection: "column", justifyContent: "space-between" }}>
                    <div></div>
                    <div style={{ color: weekly_percentage === 0 ? undefined : weekly_percentage > 0 ? green : red }}>
                      {weekly_percentage > 0 ? "+" : ""}
                      {numeral(weekly_percentage).format("0,0[.][00]")}%
                    </div>
                  </div>
                </td>
              </tr>,
              daily_data_param ? (
                daily_data_param?.week_no == week_no ? (
                  <tr>
                    <td colSpan={11} style={{ padding: 16 }}>
                      <CloseTable
                        additional_header={
                          <div className="tableheader" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                            <a
                              onClick={() => {
                                set_daily_data_param(undefined);
                              }}
                              className="button"
                              style={{ padding: 8 }}
                            >
                              <CloseIcon />
                            </a>
                          </div>
                        }
                        hide_columns={["closed_by", "entry_price", "tp", "sl"]}
                        start_date={daily_data_param?.date.startOf("day").format("YYYY-MM-DD")}
                        end_date={daily_data_param?.date.endOf("day").format("YYYY-MM-DD")}
                      />
                    </td>
                  </tr>
                ) : null
              ) : null,
            ];
          })}

          <tfoot>
            <tr>
              <td colSpan={8} className="week-column"></td>
              <td>
                <div>
                  <span>{numeral(total_investment).format("0,0[.][00]")}</span>
                  <span> | </span>
                  <span style={{ color: over_all_total_pnl === 0 ? undefined : total_roe > 0 ? green : red }}>
                    {over_all_total_pnl === 0 ? "" : total_roe > 0 ? "+" : "-"}
                    {numeral(over_all_total_pnl).format("0,0[.][00]")}
                  </span>
                </div>
              </td>
              <td>
                <div style={{ color: total_roe === 0 ? undefined : total_roe > 0 ? green : red }}>
                  {total_roe > 0 ? "+" : ""}
                  {numeral(total_roe).format("0,0[.][00]")}
                </div>
              </td>
              <td>
                {" "}
                <div style={{ color: total_percentage === 0 ? undefined : total_percentage > 0 ? green : red }}>
                  {total_percentage > 0 ? "+" : ""}
                  {numeral(total_percentage).format("0,0[.][00]")}%
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );

    return template;
  };
  return [
    <Dialog
      open={dialog_data !== undefined}
      onClose={
        dialog_data?.cancel_cb
          ? dialog_data?.cancel_cb
          : () => {
              set_dialog_data(undefined);
            }
      }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialog_data?.title || ""}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialog_data?.msg || ""}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={
            dialog_data?.cancel_cb
              ? dialog_data?.cancel_cb
              : () => {
                  set_dialog_data(undefined);
                }
          }
          autoFocus
        >
          {dialog_data?.type === "confirmation" ? "Go Back" : "Close"}
        </Button>
        {dialog_data?.type === "confirmation" ? (
          <Button
            onClick={() => {
              set_dialog_data(undefined);
              dialog_data?.cb();
            }}
          >
            Leave
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>,
    <div>
      {selected_month.isValid() ? (
        <div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "end" }}>
            <h1 style={{ margin: 0, textTransform: "uppercase", lineHeight: 1 }}>{moment(selected_month).format("MMMM YYYY")}</h1>
            <div style={{ justifySelf: "flex-end" }}>
              <DatePicker value={selected_month} onChange={(val) => set_selected_month(val)} views={["month", "year"]} />
            </div>
          </div>
          <div style={{ marginTop: 16 }}></div>
          {calendar_template.length != 0 ? generate_calendar() : null}
        </div>
      ) : null}
    </div>,
  ];
};

export default PNLWeeklyTable;

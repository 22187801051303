import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { green, red } from "../../../keys";
import numeral from "numeral";
import moment from "moment/moment";

// const rows = [
//   { id: 1, exchange: "Binance", strategy: "Hello", amount: "World", timeframe: "World", pair: "World", action: "Buy", entry: "World", tp: "World", sl: "World", opentime: "World", pnl: "World", btn: { id: "eqrr", img: "jrea" } },
//   { id: 2, exchange: "CoinBase", strategy: "Hello", amount: "Me", timeframe: "World", pair: "World", action: "Sell", entry: "World", tp: "World", sl: "World", opentime: "World", pnl: "World", btn: { id: "eqrr", img: "jrea" } },
//   { id: 3, exchange: "Binance", strategy: "Hel asd asd as ds adaslo", amount: "Me", timeframe: "World", pair: "World", action: "Sell", entry: "World", tp: "World", sl: "World", opentime: "World", pnl: "World", btn: { id: "eqrr", img: "jrea" } },
//   { id: 4, exchange: "Binance", strategy: "Hello", amount: "Me", timeframe: "World", pair: "World", action: "Buy", entry: "World", tp: "World", sl: "World", opentime: "World", pnl: "World", btn: { id: "eqrr", img: "jrea" } },
// ];

const OpenTable = ({ exchange = "", webSocket, open_position_list = [], set_open_position_list = () => {} }) => {
  const columns = [
    { flex: 1, minWidth: 100, headerAlign: "center", align: "center", field: "exchange", headerName: "Exchange" },
    { flex: 1, minWidth: 100, headerAlign: "center", align: "center", field: "strategy", headerName: "Bot Strategy" },
    {
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      field: "amount",
      headerName: "Amount",
      sortable: false,
      renderCell: (props) => {
        const { hasFocus, value } = props;
        let val = (value || 0) * 1;
        return [numeral(val).format("0,0[.][0000]")];
      },
    },
    { flex: 1, minWidth: 100, headerAlign: "center", align: "center", field: "timeframe", headerName: "Timeframe" },
    { flex: 1, minWidth: 100, headerAlign: "center", align: "center", field: "pair", headerName: "Pair" },
    { flex: 1, minWidth: 100, headerAlign: "center", align: "center", field: "action", sortable: false, headerName: "Action" },
    {
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      field: "entry",
      headerName: "Entry",
      sortable: false,
      renderCell: (props) => {
        const { hasFocus, value } = props;
        let val = (value || 0) * 1;
        return [numeral(val).format("0,0[.][0000]")];
      },
    },
    {
      flex: 1,
      minWidth: 75,
      headerAlign: "center",
      align: "center",
      field: "tp",
      headerName: "Take Profit",
      sortable: false,
      width: 75,
      renderCell: (props) => {
        const { hasFocus, value } = props;
        let val = (value || 0) * 1;
        return [numeral(val).format(val < 100 ? "0,0[.][00000]" : "0,0[.][00]")];
      },
    },
    {
      flex: 1,
      minWidth: 75,
      headerAlign: "center",
      align: "center",
      field: "sl",
      headerName: "Stop Loss",
      sortable: false,
      width: 75,
      renderCell: (props) => {
        const { hasFocus, value } = props;
        let val = (value || 0) * 1;
        return [numeral(val).format(val < 100 ? "0,0[.][00000]" : "0,0[.][00]")];
      },
    },
    {
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      field: "reward",
      headerName: "Reward",
      sortable: false,
      renderCell: (props) => {
        const { hasFocus, value } = props;
        let val = (value || 0) * 1;
        return [numeral(val).format("0,0[.][00000]")];
      },
    },
    {
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      field: "risk",
      headerName: "Risk",
      sortable: false,
      renderCell: (props) => {
        const { hasFocus, value } = props;
        let val = (value || 0) * 1;
        return [numeral(val).format("0,0[.][00000]")];
      },
    },
    {
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      field: "opentime",
      headerName: "Open Time",
      sortable: false,
      renderCell: (props) => {
        const { hasFocus, value } = props;
        return [moment(value).format("MM/DD/YYYY hh:mm A")];
      },
    },
    {
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      field: "pnl",
      headerName: "PnL",
      sortable: false,
      renderCell: (props) => {
        const { hasFocus, value, row } = props;
        let pnl = (value || 0) * 1;
        return [
          <div style={{ textAlign: "center", color: pnl < 0 ? red : pnl > 0 ? green : undefined }}>
            <div>{numeral(pnl).format("0,0[.][00]") + " USDT "}</div>
            <div>({numeral(row.pnl_pct).format("0,0[.][00]")} %)</div>
          </div>,
        ];
      },
    },
    {
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      field: "btn",
      headerName: "",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (props) => {
        const { hasFocus, value } = props;
        if (value.status === "waiting_for_response") {
          return null;
        }
        return [
          <button
            onClick={() => {
              if (webSocket.current[exchange]) {
                webSocket.current[exchange].send(
                  JSON.stringify({
                    type: "stop_bot",
                    id: value.id,
                  })
                );
                // let temp = [...open_position_list];
                // temp = temp.filter((row) => row.id === value?.id);
                // set_open_position_list(temp);
              }
            }}
            className="button"
            style={{ backgroundColor: red, color: "white" }}
          >
            Stop
          </button>,
        ];
      },
    },
  ];
  const [rows, set_rows] = useState([]);
  useEffect(() => {
    let temp = [];
    for (let i = 0; i < open_position_list.length; i++) {
      const el = open_position_list[i];
      temp.push({
        ...el,
        btn: { ...el },
        pnl_data: { ...el },
      });
    }
    set_rows(temp);
  }, [open_position_list]);
  return (
    <div style={{ height: rows.length === 0 ? 200 : rows.length >= 5 ? 350 : undefined, width: "100%" }}>
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25, page: 0 },
          },
        }}
        hideFooter={rows.length > 25 ? false : true}
        className="positiontables"
        rows={rows}
        columns={columns}
      />
    </div>
  );
};

export default OpenTable;

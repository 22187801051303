const MountableDrawer = ({ children, style, closable = true, title, onClose = () => {}, close_btn_style = {}, show = false }) => {
  return show
    ? [
        <div style={{ position: "absolute", inset: 0 }}>
          <div style={{ position: "relative", width: "100%", height: "100%", overflow: "hidden", left: 0 }}>
            <div className="mountable-drawer" style={style}>
              {closable || title !== undefined ? (
                <div style={{ paddingTop: 8, paddingBottom: 8, display: "flex", flexDirection: "row", alignItems: "center", borderBottom: "1px solid #666666" }}>
                  <div style={{ flexGrow: 1 }}>{title !== undefined ? title : null}</div>
                  {closable ? (
                    <a onClick={onClose} className="button" style={{ ...{ paddingLeft: 4, paddingRight: 4 }, close_btn_style }}>
                      X
                    </a>
                  ) : null}
                </div>
              ) : null}
              {children}
            </div>
          </div>
        </div>,
      ]
    : null;
};

export default MountableDrawer;

import React, { useState, useEffect } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { yellow } from "../../../keys";
const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
const StrategyBoard = ({
  set_pair = () => {},
  ss_tf = "5m",
  set_ss_tf = () => {},
  is_tradable_data = {},
  strategy_pair = "",
  set_strategy_pair = () => {},
  strategy_timeframe = "",
  set_strategy_timeframe = () => {},
  selected_strategy,
  set_selected_strategy = () => {},
  strategies = [],
  pairwatchlist = [],
  timeframes = [],
  set_timeframes = () => {},
  set_open_strat_selection = () => {},
  open_strat_selection = false,
  open_additional_params = undefined,
  set_open_additional_params = () => {},
  active_tab = "watchlist",
  set_active_tab = () => {},
  screenwidth,
  exchange = "",
}) => {
  const onChange = (event, value) => {
    set_strategy_timeframe(value);
    set_ss_tf(value);
  };
  const [strat_index, set_strat_index] = useState(0);
  useEffect(() => {
    set_strat_index(0);
  }, [open_strat_selection]);
  useEffect(() => {
    console.log("strat_index", strat_index);
  }, [strat_index]);
  return [
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <h4>PAIR SCANNER</h4>
        {screenwidth < 900 ? (
          <button
            onClick={() => {
              set_open_strat_selection((prev) => !prev);
            }}
            className="button"
            style={{ backgroundColor: "white", border: 0, padding: 0, borderRadius: "50%", width: 24, height: 24, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          >
            <CloseIcon fontSize="8" />
          </button>
        ) : null}
      </div>

      <div className="StrategyBoardTabs">
        <Tabs variant="scrollable" scrollButtons={"auto"} allowScrollButtonsMobile value={strategy_timeframe} onChange={onChange} aria-label="StrategyBoardTimeFrame">
          {timeframes.map((timeframe, index) => {
            return [<Tab className="dense-tabs" style={{ textTransform: "lowercase" }} label={timeframe} value={timeframe} />];
          })}
        </Tabs>
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", flexWrap: "wrap", width: "100%", marginTop: 16, overflow: "auto" }}>
        {strategies.map((strategy, index) => {
          if (screenwidth < 900) if (index != strat_index) return null;
          return [
            <div className="pair-scanner-grid" style={{}} key={strategy.id}>
              <div style={{ marginTop: "1.33em", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ minWidth: 24 }}>
                  {screenwidth < 900 && strat_index > 0 ? (
                    <Button
                      onClick={() => {
                        let temp = strat_index * 1 - 1;
                        if (temp < 0) return;
                        set_strat_index(temp);
                      }}
                      style={{ color: "white", padding: 0, minWidth: 0 }}
                    >
                      <ArrowBackIosNewIcon />
                    </Button>
                  ) : null}
                </div>
                <h4 style={{ marginTop: 0, textAlign: "center", textWrap: "nowrap", whiteSpace: "nowrap", marginBottom: 0 }}>
                  {strategy.name}
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div color="inherit">{strategy.tooltip}</div>
                      </React.Fragment>
                    }
                  >
                    <HelpIcon fontSize="" style={{ marginLeft: 4, marginTop: -4 }} />
                  </HtmlTooltip>
                </h4>
                <div style={{ minWidth: 24 }}>
                  {screenwidth < 900 && strat_index < strategies.length - 1 ? (
                    <Button
                      onClick={() => {
                        let temp = strat_index * 1 + 1;
                        if (temp > strategies.length - 1) return;
                        set_strat_index(temp);
                      }}
                      style={{ color: "white", padding: 0, minWidth: 0 }}
                    >
                      <ArrowForwardIosIcon />
                    </Button>
                  ) : null}
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", overflow: "auto", maxHeight: "100%" }}>
                <React.Fragment>
                  <div style={{ marginBottom: 8 }}></div>
                  <div>
                    <Button
                      variant="contained"
                      fullWidth
                      disabled={strategy?.id === "Technical" || strategy?.id == "LongShort" ? false : true}
                      style={{ backgroundColor: "#2b3139", color: "white", opacity: strategy?.id === "Technical" || strategy?.id == "LongShort" ? undefined : 0 }}
                      onClick={
                        strategy?.id === "Technical" || strategy?.id == "LongShort"
                          ? () => {
                              set_open_additional_params(strategy?.id);
                            }
                          : undefined
                      }
                    >
                      Parameters
                    </Button>
                  </div>
                  <div style={{ marginBottom: 16 }}></div>
                </React.Fragment>
                {pairwatchlist.map((pair, pair_index) => {
                  const is_tradable = ((is_tradable_data[pair.pair] || {})[strategy.id + "_" + pair.pair] || 0) == 0 ? false : true;
                  return [
                    <Button
                      style={{
                        color: is_tradable ? yellow : "white",
                      }}
                      onClick={() => {
                        set_selected_strategy(strategy);
                        set_strategy_pair(pair.pair);
                        set_open_strat_selection(false);
                        set_pair((prev) => {
                          prev[exchange] = pair.pair;
                          return prev;
                        });
                      }}
                      key={strategy.id + "_" + pair.pair}
                    >
                      {pair.pair}
                    </Button>,
                  ];
                })}
              </div>
            </div>,
          ];
        })}
      </div>
    </div>,
  ];
};

export default StrategyBoard;

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ExchangeWinRateTable from "./AnalyticsExchangeWR";
import React, { useEffect, useState } from "react";
import StrategyWinRateTable from "./AnalyticsStrategyWinRateTable";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import axios from "axios";
import { api_url, component_bg_color } from "../../../keys";
import CircularProgress from "@mui/material/CircularProgress";
import StrategyWinRatioTable from "./AnalyticsStrategyWinRatioTable";
const AnalyticsTabContent = ({ exchange_list }) => {
  const [dialog_data, set_dialog_data] = useState(undefined);
  const [fetching_data, set_fetching_data] = useState(false);

  const [active_tab, set_active_tab] = useState("All");
  const change_active_tab = (event, value) => {
    set_active_tab(value);
    get_analytics_data(value);
  };

  const get_analytics_data = (exchange) => {
    set_fetching_data(true);
    let user_data = sessionStorage.getItem("user_data");
    if (user_data === null || user_data === "") {
      user_data = undefined;
    }
    user_data = user_data ? JSON.parse(user_data) : {};
    let page_size = 10;
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        api_url + "/get_analytics/",
        {
          login_token: user_data.login_token,
          username: user_data.username,
          exchange: exchange === "All" ? undefined : exchange,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          //continue
          successful_saving(res?.data?.data);
          set_fetching_data(false);
        } else {
          console.log("error?", res.data);
          set_fetching_data(false);
          set_dialog_data({
            title: "Request Error",
            msg: "Something went wrong, Please try again later.",
            type: "alert",
            cancel_cb: () => {
              set_dialog_data(undefined);
            },
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        set_fetching_data(false);
        set_dialog_data({
          title: "Request Error",
          msg: "Something went wrong, Please try again later.",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
        });
      });
  };
  const successful_saving = (data) => {
    set_fetching_data(false);
    return_data(data);
  };
  // const [page_index, set_page_index] = useState(1);
  // const [num_page, set_num_page] = useState(1);
  // const [has_next, set_has_next] = useState(false);
  // const [has_previous, set_has_previous] = useState(false);
  const [analytics_data, set_analytics_data] = useState([]);
  const [strategy_data, set_strategy_data] = useState([]);
  const return_data = (data) => {
    if (data?.exchange_data) {
      set_analytics_data(data?.exchange_data);
    }
    if (data?.strategy_data) {
      set_strategy_data(data?.strategy_data);
    }
  };
  useEffect(() => {
    get_analytics_data();
    return () => set_analytics_data([]);
  }, []);
  return [
    <Dialog
      open={dialog_data !== undefined}
      onClose={
        dialog_data?.cancel_cb
          ? dialog_data?.cancel_cb
          : () => {
              set_dialog_data(undefined);
            }
      }
      aria-labelledby="alert-dialog-titles"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialog_data?.title || ""}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialog_data?.msg || ""}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={
            dialog_data?.cancel_cb
              ? dialog_data?.cancel_cb
              : () => {
                  set_dialog_data(undefined);
                }
          }
          autoFocus
        >
          {dialog_data?.type === "confirmation" ? "Go Back" : "Close"}
        </Button>
        {dialog_data?.type === "confirmation" ? (
          <Button
            onClick={() => {
              set_dialog_data(undefined);
              dialog_data?.cb();
            }}
          >
            Leave
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>,
    <div style={{ position: fetching_data ? "relative" : undefined }}>
      {fetching_data ? (
        <div
          style={{
            zIndex: 9999,
            backgroundColor: component_bg_color,
            opacity: 0.5,
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : null}
      <Box className="AnalyticsTab">
        <Tabs variant="scrollable" scrollButtons={"auto"} allowScrollButtonsMobile value={active_tab} onChange={change_active_tab} aria-label="AnalyticsTabs">
          <Tab className="dense-tabs" label="All Xs" value="All" id="AllXs" />
          {exchange_list.map((exchange, index) => {
            return [<Tab className="dense-tabs" label={exchange} value={exchange} id={`${exchange}Tab`} />];
          })}
        </Tabs>
      </Box>
      <Box sx={{ width: "100%", p: 3 }}>
        <ExchangeWinRateTable strategy_data={strategy_data} analytics_data={analytics_data} />
        <div style={{ marginTop: 96 }}></div>
        <StrategyWinRateTable strategy_data={strategy_data} analytics_data={analytics_data} />
        {active_tab == "All" ? (
          <React.Fragment>
            <div style={{ marginTop: 24 }}></div>
            <StrategyWinRatioTable strategy_data={strategy_data} analytics_data={analytics_data} />
          </React.Fragment>
        ) : null}
      </Box>
    </div>,
  ];
};

export default AnalyticsTabContent;

import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { component_bg_color, api_url, session_valid_for } from "../../keys";
import axios from "axios";

const PasswordDrawer = ({ open_change_password = false, set_open_change_password = () => {} }) => {
  const [dialog_data, set_dialog_data] = useState(undefined);
  const [saving_data, set_saving_data] = useState(false);
  const [old_password, set_old_password] = useState("");
  const [new_password, set_new_password] = useState("");
  const [confirm_password, set_confirm_password] = useState("");

  const [show_old_password, set_show_old_password] = useState(false);
  const [show_new_password, set_show_new_password] = useState(false);
  const [show_confirm_password, set_show_confirm_password] = useState(false);
  const reset_fields = () => {
    set_saving_data(false);
    set_show_old_password(false);
    set_show_new_password(false);
    set_show_confirm_password(false);
    set_old_password("");
    set_new_password("");
    set_confirm_password("");
  };

  const save_settings = () => {
    set_saving_data(true);
    let user_data = sessionStorage.getItem("user_data");
    if (user_data === null || user_data === "") {
      user_data = undefined;
    }
    user_data = user_data ? JSON.parse(user_data) : {};
    if (old_password == "" || new_password == "" || confirm_password == "") {
      set_dialog_data({
        title: "Missing Input",
        msg: "Please complete the missing information.",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
        },
      });
      set_saving_data(false);
      return;
    }
    if (new_password != confirm_password) {
      set_dialog_data({
        title: "Password Mismatch",
        msg: "New Password and Confirm Password not match.",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
        },
      });
      set_saving_data(false);
      return;
    }
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        api_url + "/change_password/",
        {
          login_token: user_data.login_token,
          username: user_data.username,
          old_password,
          new_password,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          //continue
          successful_saving(true, res?.data?.data);
          set_saving_data(false);
        } else {
          console.log("error?", res.data);
          set_saving_data(false);
          set_dialog_data({
            title: "Request Error",
            msg: res.data.msg,
            type: "alert",
            cancel_cb: () => {
              set_dialog_data(undefined);
            },
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        set_saving_data(false);
        set_dialog_data({
          title: "Request Error",
          msg: "Something went wrong, Please try again later.",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
        });
      });
  };
  const successful_saving = (changed, data) => {
    set_saving_data(false);
    set_dialog_data({
      title: "Successfully Changed Account Password",
      msg: "Successfully changed your account password. ",
      type: "alert",
      cancel_cb: () => {
        set_open_change_password(false);
        set_dialog_data(undefined);
      },
    });
  };
  const handleClose = () => {
    set_open_change_password(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open_change_password) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open_change_password]);
  useEffect(() => {
    reset_fields();
  }, [open_change_password]);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return [
    saving_data ? (
      <div
        style={{
          zIndex: 9999,
          backgroundColor: component_bg_color,
          opacity: 0.9,
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    ) : null,
    <Dialog
      open={dialog_data !== undefined}
      onClose={
        dialog_data?.cancel_cb
          ? dialog_data?.cancel_cb
          : () => {
              set_dialog_data(undefined);
            }
      }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialog_data?.title || ""}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialog_data?.msg || ""}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={
            dialog_data?.cancel_cb
              ? dialog_data?.cancel_cb
              : () => {
                  set_dialog_data(undefined);
                }
          }
          autoFocus
        >
          {dialog_data?.type === "confirmation" ? "Go Back" : "Close"}
        </Button>
        {dialog_data?.type === "confirmation" ? (
          <Button
            onClick={() => {
              set_dialog_data(undefined);
              dialog_data?.cb();
            }}
          >
            Leave
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>,
    <Dialog open={open_change_password} onClose={handleClose} scroll={"paper"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
      <DialogTitle id="scroll-dialog-title">Change Account Password</DialogTitle>
      <DialogContent dividers={"paper"} style={{}}>
        <DialogContentText ref={descriptionElementRef} tabIndex={-1}>
          <TextField
            value={old_password}
            onChange={(e) => {
              set_old_password(e.target.value);
            }}
            disabled={saving_data}
            placeholder="Current Password"
            type={show_old_password ? "text" : "password"}
            size="small"
            label="Current Password"
            fullWidth
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      set_show_old_password((prev) => !prev);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {show_old_password ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            value={new_password}
            onChange={(e) => {
              set_new_password(e.target.value);
            }}
            disabled={saving_data}
            placeholder="New Password"
            type={show_new_password ? "text" : "password"}
            size="small"
            label="New Password"
            fullWidth
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      set_show_new_password((prev) => !prev);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {show_new_password ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            value={confirm_password}
            onChange={(e) => {
              set_confirm_password(e.target.value);
            }}
            disabled={saving_data}
            placeholder="Confirm Password"
            type={show_confirm_password ? "text" : "password"}
            size="small"
            label="Confirm Password"
            fullWidth
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      set_show_confirm_password((prev) => !prev);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {show_confirm_password ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={save_settings}>Submit</Button>
      </DialogActions>
    </Dialog>,
  ];
};

export default PasswordDrawer;

import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import OpenTable from "./sub-module/OpenTable";
import CloseTable from "./sub-module/CloseTable";
import AnalyticsTabContent from "./sub-module/AnalyticsTabContent";
import PNLTab from "./PnlTab";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
const PositionTabs = ({ exchange = "", close_position_list = [], set_close_position_list = () => {}, webSocket, exchange_list = [], open_position_list = [], set_open_position_list = () => {} }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return [
    <Box sx={{ width: "100%" }}>
      <Box className="PositionTab" sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs variant="scrollable" scrollButtons={"auto"} allowScrollButtonsMobile value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab className="dense-tabs" label="Open" id="OpenTab" />
          <Tab className="dense-tabs" label="Close" id="CloseTab" />
          <Tab className="dense-tabs" label="Analytics" id="AnalyticsTab" />
          <Tab className="dense-tabs" label="PNL" id="PNLTab" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <OpenTable webSocket={webSocket} exchange={exchange} set_open_position_list={set_open_position_list} open_position_list={open_position_list} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CloseTable webSocket={webSocket} exchange={exchange} close_position_list={close_position_list} set_close_position_list={set_close_position_list} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AnalyticsTabContent exchange_list={exchange_list} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PNLTab exchange_list={exchange_list} />
      </TabPanel>
    </Box>,
  ];
};
export default PositionTabs;

import React, { useState, useEffect } from "react";
import moment from "moment";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { green, red, yellow } from "../../keys";
import PNLMonthlyTable from "./sub-module/PNLMonthly";
import PNLWeeklyTable from "./sub-module/PNLWeeklyTable";
import numeral from "numeral";
const PNLTab = ({ exchange_list = [] }) => {
  const [loading, set_loading] = useState(false);
  const [date_interval, set_date_interval] = useState("Daily / Weekly");

  const [total_investment, set_total_investment] = useState(0);
  const [total_pl, set_total_pl] = useState(0);

  const change_date_interval = (event, value) => {
    set_date_interval(value);
    set_total_investment(0);
    set_total_pl(0);
  };
  const [active_tab, set_active_tab] = useState("All");
  const change_active_tab = (event, value) => {
    set_active_tab(value);
    set_total_investment(0);
    set_total_pl(0);
  };

  return [
    <div>
      <Box className="PNLTab">
        <Tabs className="PNLTab-exchanges" variant="scrollable" scrollButtons={"auto"} allowScrollButtonsMobile value={active_tab} onChange={change_active_tab} aria-label="PNLTabs">
          <Tab className="dense-tabs" label="All Xs" value="All" id="AllXs" />
          {exchange_list.map((exchange, index) => {
            return [<Tab className="dense-tabs" label={exchange} value={exchange} id={`${exchange}Tab`} />];
          })}
        </Tabs>
        <Tabs variant="scrollable" scrollButtons={"auto"} allowScrollButtonsMobile value={date_interval} onChange={change_date_interval} aria-label="DateIntervalTabs">
          {["Daily / Weekly", "Monthly"].map((exchange, index) => {
            return [<Tab className="dense-tabs" label={exchange} value={exchange} id={`${exchange}Tab`} />];
          })}
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }} style={{ padding: 32, paddingTop: 0 }}>
        <div className="pnlTotalsBoxesContainer">
          <div className="pnlTotalsBoxes">
            <h2 style={{ color: yellow }}>{numeral(total_investment).format("0,0[.][00]")} USDT</h2>
            <div className="subtitle">Total Invesment Amount</div>
          </div>
          <div className="pnlTotalsBoxes pnlTotalsBoxesLastItem">
            <h2 style={{ color: total_pl === 0 ? undefined : total_pl > total_investment ? green : red }}>
              {total_pl == 0 ? "" : total_pl > total_investment ? "+ " : "-"}
              {numeral(total_pl).format("0,0[.][00]")} USDT ({numeral((total_pl / total_investment) * 100 - 100).format("0,0[.][00]")}%)
            </h2>
            <div className="subtitle">Total Profit / Loss</div>
          </div>
        </div>
        <div style={{ marginTop: 16 }}></div>
        {date_interval === "Daily / Weekly" ? (
          <PNLWeeklyTable exchange={active_tab} loading={loading} set_loading={set_loading} total_pl={total_pl} set_total_pl={set_total_pl} total_investment={total_investment} set_total_investment={set_total_investment} />
        ) : (
          <PNLMonthlyTable exchange={active_tab} loading={loading} set_loading={set_loading} total_pl={total_pl} set_total_pl={set_total_pl} total_investment={total_investment} set_total_investment={set_total_investment} />
        )}
      </Box>
    </div>,
  ];
};
export default PNLTab;

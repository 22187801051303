import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import { component_bg_color, api_url, session_valid_for } from "../../keys";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const SettingDrawer = ({ open_user_settings = false, set_open_user_settings = () => {} }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dialog_data, set_dialog_data] = useState(undefined);
  const [saving_data, set_saving_data] = useState(false);
  const [getting_data, set_getting_data] = useState(false);
  const [settings, set_settings] = useState(undefined);
  const [binance_api_key, set_binance_api_key] = useState("");
  const [binance_secret_key, set_binance_secret_key] = useState("");
  const [binance_test_mode, set_binance_test_mode] = useState(true);

  const [bybit_api_key, set_bybit_api_key] = useState("");
  const [bybit_secret_key, set_bybit_secret_key] = useState("");
  const [bybit_test_mode, set_bybit_test_mode] = useState(true);

  const [kucoin_api_key, set_kucoin_api_key] = useState("");
  const [kucoin_passphrase, set_kucoin_passphrase] = useState("");
  const [kucoin_secret_key, set_kucoin_secret_key] = useState("");
  const [kucoin_test_mode, set_kucoin_test_mode] = useState(true);

  const [bingx_api_key, set_bingx_api_key] = useState("");
  const [bingx_secret_key, set_bingx_secret_key] = useState("");
  const [bingx_test_mode, set_bingx_test_mode] = useState(true);

  const [bitget_api_key, set_bitget_api_key] = useState("");
  const [bitget_passphrase, set_bitget_passphrase] = useState("");
  const [bitget_secret_key, set_bitget_secret_key] = useState("");
  const [bitget_test_mode, set_bitget_test_mode] = useState(true);

  const reset_fields = () => {
    set_getting_data(false);
    set_saving_data(false);
    set_settings(undefined);
    set_binance_api_key("");
    set_binance_secret_key("");
    set_binance_test_mode(true);

    set_bybit_api_key("");
    set_bybit_secret_key("");
    set_bybit_test_mode(true);

    set_kucoin_api_key("");
    set_kucoin_passphrase("");
    set_kucoin_secret_key("");
    set_kucoin_test_mode(true);

    set_bingx_api_key("");
    set_bingx_secret_key("");
    set_bingx_test_mode(true);

    set_bitget_api_key("");
    set_bitget_passphrase("");
    set_bitget_secret_key("");
    set_bitget_test_mode(true);
  };
  const redirect_to_login = () => {
    sessionStorage.removeItem("user_data");
    sessionStorage.removeItem("session_timeout");
    if (location.pathname === "/login") {
      return;
    }
    navigate("/login", {
      state: {
        test_params: "test_params",
      },
    });
  };
  const check_account = () => {
    set_getting_data(true);
    let user_data = sessionStorage.getItem("user_data");
    if (user_data === null || user_data === "") {
      user_data = undefined;
    }
    user_data = user_data ? JSON.parse(user_data) : {};
    const session_timeout = parseInt(sessionStorage.getItem("session_timeout")) || new Date("2023/06/15");
    //check if session exist
    if (user_data.login_token === "" || user_data.login_token === null || user_data.login_token === undefined) {
      redirect_to_login();
      set_getting_data(false);
      set_open_user_settings(false);
      return;
    }

    if (session_timeout === "" || session_timeout === null || session_timeout === undefined) {
      redirect_to_login();
      set_getting_data(false);
      set_open_user_settings(false);
      return;
    }
    const current_dt = Date.now();

    //check if session timeout is reached
    const timeSpan = 1000 * 60 * 60 * 24 * session_valid_for;
    if (current_dt - session_timeout >= timeSpan) {
      redirect_to_login();
      set_getting_data(false);
      set_open_user_settings(false);
      return;
    }
    get_setting_data(user_data);
  };
  const get_setting_data = (user_data) => {
    //check if account exist
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        api_url + "/get_settings/",
        { login_token: user_data.login_token, username: user_data.username },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          //continue
          return_data(res?.data?.data);
          set_getting_data(false);
        } else {
          set_getting_data(false);
          set_dialog_data({
            title: "Request Error",
            msg: "Something went wrong, Please try again later.",
            type: "alert",
            cancel_cb: () => {
              set_open_user_settings(false);
              set_dialog_data(undefined);
            },
          });
        }
      })
      .catch((err) => {
        set_getting_data(false);
        set_dialog_data({
          title: "Request Error",
          msg: "Something went wrong, Please try again later.",
          type: "alert",
          cancel_cb: () => {
            set_open_user_settings(false);
            set_dialog_data(undefined);
          },
        });
      });
  };
  const return_data = (data) => {
    set_getting_data(false);
    set_settings(data);
    set_binance_api_key(data?.binance_public_key);
    set_binance_secret_key(data?.binance_secret_key);
    set_binance_test_mode(data?.binance_test_mode);

    set_bybit_api_key(data?.bybit_public_key);
    set_bybit_secret_key(data?.bybit_secret_key);
    set_bybit_test_mode(data?.bybit_test_mode);

    set_kucoin_api_key(data?.kucoin_public_key);
    set_kucoin_passphrase(data?.kucoin_passphrase);
    set_kucoin_secret_key(data?.kucoin_secret_key);
    set_kucoin_test_mode(data?.kucoin_test_mode);

    set_bingx_api_key(data?.bingx_public_key);
    set_bingx_secret_key(data?.bingx_secret_key);
    set_bingx_test_mode(data?.bingx_test_mode);

    set_bitget_api_key(data?.bitget_public_key);
    set_bitget_passphrase(data?.bitget_passphrase);
    set_bitget_secret_key(data?.bitget_secret_key);
    set_bitget_test_mode(data?.bitget_test_mode);
  };
  const save_settings = () => {
    set_saving_data(true);
    if (check_changed_field() === false) {
      successful_saving(false);
      return;
    }
    let user_data = sessionStorage.getItem("user_data");
    if (user_data === null || user_data === "") {
      user_data = undefined;
    }
    user_data = user_data ? JSON.parse(user_data) : {};
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        api_url + "/save_settings/",
        {
          login_token: user_data.login_token,
          username: user_data.username,
          binance_public_key: binance_api_key,
          binance_secret_key: (binance_secret_key || "").replace(/\*/g, "") === "" ? undefined : binance_secret_key,
          binance_test_mode: binance_test_mode,

          bybit_public_key: bybit_api_key,
          bybit_secret_key: (bybit_secret_key || "").replace(/\*/g, "") === "" ? undefined : bybit_secret_key,
          bybit_test_mode: bybit_test_mode,

          kucoin_public_key: kucoin_api_key,
          kucoin_secret_key: (kucoin_secret_key || "").replace(/\*/g, "") === "" ? undefined : kucoin_secret_key,
          kucoin_passphrase: (kucoin_passphrase || "").replace(/\*/g, "") === "" ? undefined : kucoin_passphrase,
          kucoin_test_mode: kucoin_test_mode,

          bingx_public_key: bingx_api_key,
          bingx_secret_key: (bingx_secret_key || "").replace(/\*/g, "") === "" ? undefined : bingx_secret_key,
          bingx_test_mode: bingx_test_mode,

          bitget_public_key: bitget_api_key,
          bitget_secret_key: (bitget_secret_key || "").replace(/\*/g, "") === "" ? undefined : bitget_secret_key,
          bitget_passphrase: (bitget_passphrase || "").replace(/\*/g, "") === "" ? undefined : bitget_passphrase,
          bitget_test_mode: bitget_test_mode,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          //continue
          successful_saving(true, res?.data?.data);
          set_saving_data(false);
        } else {
          console.log("error?", res.data);
          set_saving_data(false);
          set_dialog_data({
            title: "Request Error",
            msg: "Something went wrong, Please try again later.",
            type: "alert",
            cancel_cb: () => {
              set_open_user_settings(false);
              set_dialog_data(undefined);
            },
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        set_saving_data(false);
        set_dialog_data({
          title: "Request Error",
          msg: "Something went wrong, Please try again later.",
          type: "alert",
          cancel_cb: () => {
            set_open_user_settings(false);
            set_dialog_data(undefined);
          },
        });
      });
  };
  const successful_saving = (changed, data) => {
    set_saving_data(false);
    set_dialog_data({
      title: "Successfully Saved",
      msg: "Successfully saved your account setting. " + (changed ? "This page will reload after closing this window." : ""),
      type: "alert",
      cancel_cb: () => {
        if (changed) {
          return_data(data);
          window.location.reload();
        }

        set_dialog_data(undefined);
      },
    });
  };
  const handleClose = () => {
    if (check_changed_field()) {
      set_dialog_data({
        title: "Unsaved Changes",
        msg: "You made some changes. Do you want to leave this window?",
        type: "confirmation",
        cancel_cb: () => {
          set_dialog_data(undefined);
        },
        cb: () => {
          set_open_user_settings(false);
        },
      });
    } else {
      set_open_user_settings(false);
    }
  };
  const check_changed_field = () => {
    if (binance_api_key !== settings?.binance_public_key) {
      return true;
    } else if (binance_secret_key !== settings?.binance_secret_key) {
      return true;
    } else if (binance_test_mode !== settings?.binance_test_mode) {
      return true;
    }

    if (bybit_api_key !== settings?.bybit_public_key) {
      return true;
    } else if (bybit_secret_key !== settings?.bybit_secret_key) {
      return true;
    } else if (bybit_test_mode !== settings?.bybit_test_mode) {
      return true;
    }

    if (kucoin_api_key !== settings?.kucoin_public_key) {
      return true;
    } else if (kucoin_secret_key !== settings?.kucoin_secret_key) {
      return true;
    } else if (kucoin_passphrase !== settings?.kucoin_passphrase) {
      return true;
    } else if (kucoin_test_mode !== settings?.kucoin_test_mode) {
      return true;
    }

    if (bingx_api_key !== settings?.bingx_public_key) {
      return true;
    } else if (bingx_secret_key !== settings?.bingx_secret_key) {
      return true;
    } else if (bingx_test_mode !== settings?.bingx_test_mode) {
      return true;
    }

    if (bitget_api_key !== settings?.bitget_public_key) {
      return true;
    } else if (bitget_secret_key !== settings?.bitget_secret_key) {
      return true;
    } else if (bitget_passphrase !== settings?.bitget_passphrase) {
      return true;
    } else if (bitget_test_mode !== settings?.bitget_test_mode) {
      return true;
    }

    return false;
  };
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open_user_settings) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open_user_settings]);
  useEffect(() => {
    if (open_user_settings) {
      check_account();
    } else {
      reset_fields();
    }
  }, [open_user_settings]);
  if (getting_data && open_user_settings) {
    return [
      <div
        style={{
          zIndex: 9999,
          backgroundColor: component_bg_color,
          opacity: 0.9,
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>,
    ];
  }
  return [
    saving_data ? (
      <div
        style={{
          zIndex: 9999,
          backgroundColor: component_bg_color,
          opacity: 0.9,
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    ) : null,
    <Dialog
      open={dialog_data !== undefined}
      onClose={
        dialog_data?.cancel_cb
          ? dialog_data?.cancel_cb
          : () => {
              set_dialog_data(undefined);
            }
      }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialog_data?.title || ""}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialog_data?.msg || ""}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={
            dialog_data?.cancel_cb
              ? dialog_data?.cancel_cb
              : () => {
                  set_dialog_data(undefined);
                }
          }
          autoFocus
        >
          {dialog_data?.type === "confirmation" ? "Go Back" : "Close"}
        </Button>
        {dialog_data?.type === "confirmation" ? (
          <Button
            onClick={() => {
              set_dialog_data(undefined);
              dialog_data?.cb();
            }}
          >
            Leave
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>,
    <Dialog open={open_user_settings} onClose={handleClose} scroll={"paper"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
      <DialogTitle style={{ backgroundColor: "#14161f" }} id="scroll-dialog-title">
        Connection Keys Settings
      </DialogTitle>
      <DialogContent dividers={"paper"} style={{ padding: 0 }}>
        <DialogContentText ref={descriptionElementRef} tabIndex={-1}>
          <Accordion defaultExpanded={false}>
            <AccordionSummary aria-controls="panel1a-content" style={{ backgroundColor: "rgb(20, 23, 32)" }} id="panel1a-header">
              <Typography>Binance Credentials</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                value={binance_api_key}
                onChange={(e) => {
                  set_binance_api_key(e.target.value);
                }}
                placeholder="Binance API key"
                size="small"
                label="API Key"
                fullWidth
                margin="dense"
              />
              <TextField
                value={binance_secret_key}
                placeholder={(binance_secret_key || "").replace(/\*/g, "") === "" ? "**********" : "Binance secret key"}
                onChange={(e) => {
                  set_binance_secret_key((e.target.value || "").replace(/\*/g, ""));
                }}
                onFocus={() => {
                  if ((binance_secret_key || "").replace(/\*/g, "") === "") {
                    set_binance_secret_key("");
                  }
                }}
                onBlur={() => {
                  if ((binance_secret_key || "").replace(/\*/g, "") === "") {
                    set_binance_secret_key(settings?.binance_secret_key);
                  }
                }}
                size="small"
                label="Secret Key"
                fullWidth
                margin="dense"
              />
              <FormControlLabel control={<Switch checked={binance_test_mode} onChange={(e) => set_binance_test_mode(e.target.checked)} />} label="Test Mode" />
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded={false}>
            <AccordionSummary style={{ backgroundColor: "rgb(20, 23, 32)" }} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>ByBit Credentials</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                value={bybit_api_key}
                onChange={(e) => {
                  set_bybit_api_key(e.target.value);
                }}
                placeholder="ByBit API key"
                size="small"
                label="API Key"
                fullWidth
                margin="dense"
              />
              <TextField
                value={bybit_secret_key}
                placeholder={(bybit_secret_key || "").replace(/\*/g, "") === "" ? "**********" : "ByBit secret key"}
                onChange={(e) => {
                  set_bybit_secret_key((e.target.value || "").replace(/\*/g, ""));
                }}
                onFocus={() => {
                  if ((bybit_secret_key || "").replace(/\*/g, "") === "") {
                    set_bybit_secret_key("");
                  }
                }}
                onBlur={() => {
                  if ((bybit_secret_key || "").replace(/\*/g, "") === "") {
                    set_bybit_secret_key(settings?.bybit_secret_key);
                  }
                }}
                size="small"
                label="Secret Key"
                fullWidth
                margin="dense"
              />
              <FormControlLabel control={<Switch checked={bybit_test_mode} onChange={(e) => set_bybit_test_mode(e.target.checked)} />} label="Test Mode" />
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded={false}>
            <AccordionSummary style={{ backgroundColor: "rgb(20, 23, 32)" }} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>KuCoin Credentials</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                value={kucoin_api_key}
                onChange={(e) => {
                  set_kucoin_api_key(e.target.value);
                }}
                placeholder="KuCoin API key"
                size="small"
                label="API Key"
                fullWidth
                margin="dense"
              />
              <TextField
                value={kucoin_secret_key}
                placeholder={(kucoin_secret_key || "").replace(/\*/g, "") === "" ? "**********" : "KuCoin secret key"}
                onChange={(e) => {
                  set_kucoin_secret_key((e.target.value || "").replace(/\*/g, ""));
                }}
                onFocus={() => {
                  if ((kucoin_secret_key || "").replace(/\*/g, "") === "") {
                    set_kucoin_secret_key("");
                  }
                }}
                onBlur={() => {
                  if ((kucoin_secret_key || "").replace(/\*/g, "") === "") {
                    set_kucoin_secret_key(settings?.kucoin_secret_key);
                  }
                }}
                size="small"
                label="Secret Key"
                fullWidth
                margin="dense"
              />
              <TextField
                value={kucoin_passphrase}
                placeholder={(kucoin_passphrase || "").replace(/\*/g, "") === "" ? "**********" : "KuCoin passphrase"}
                onChange={(e) => {
                  set_kucoin_passphrase((e.target.value || "").replace(/\*/g, ""));
                }}
                onFocus={() => {
                  if ((kucoin_passphrase || "").replace(/\*/g, "") === "") {
                    set_kucoin_passphrase("");
                  }
                }}
                onBlur={() => {
                  if ((kucoin_passphrase || "").replace(/\*/g, "") === "") {
                    set_kucoin_passphrase(settings?.kucoin_passphrase);
                  }
                }}
                size="small"
                label="Passphrase"
                fullWidth
                margin="dense"
              />
              <FormControlLabel control={<Switch checked={kucoin_test_mode} onChange={(e) => set_kucoin_test_mode(e.target.checked)} />} label="Test Mode" />
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded={false}>
            <AccordionSummary style={{ backgroundColor: "rgb(20, 23, 32)" }} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>BingX Credentials</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                value={bingx_api_key}
                onChange={(e) => {
                  set_bingx_api_key(e.target.value);
                }}
                placeholder="BingX API key"
                size="small"
                label="API Key"
                fullWidth
                margin="dense"
              />
              <TextField
                value={bingx_secret_key}
                placeholder={(bingx_secret_key || "").replace(/\*/g, "") === "" ? "**********" : "BingX secret key"}
                onChange={(e) => {
                  set_bingx_secret_key((e.target.value || "").replace(/\*/g, ""));
                }}
                onFocus={() => {
                  if ((bingx_secret_key || "").replace(/\*/g, "") === "") {
                    set_bingx_secret_key("");
                  }
                }}
                onBlur={() => {
                  if ((bingx_secret_key || "").replace(/\*/g, "") === "") {
                    set_bingx_secret_key(settings?.bingx_secret_key);
                  }
                }}
                size="small"
                label="Secret Key"
                fullWidth
                margin="dense"
              />

              <FormControlLabel disabled control={<Switch checked={bingx_test_mode} onChange={(e) => set_bingx_test_mode(e.target.checked)} />} label="Test Mode" />
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded={false}>
            <AccordionSummary style={{ backgroundColor: "rgb(20, 23, 32)" }} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>BitGet Credentials</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                value={bitget_api_key}
                onChange={(e) => {
                  set_bitget_api_key(e.target.value);
                }}
                placeholder="bitget API key"
                size="small"
                label="API Key"
                fullWidth
                margin="dense"
              />
              <TextField
                value={bitget_secret_key}
                placeholder={(bitget_secret_key || "").replace(/\*/g, "") === "" ? "**********" : "bitget secret key"}
                onChange={(e) => {
                  set_bitget_secret_key((e.target.value || "").replace(/\*/g, ""));
                }}
                onFocus={() => {
                  if ((bitget_secret_key || "").replace(/\*/g, "") === "") {
                    set_bitget_secret_key("");
                  }
                }}
                onBlur={() => {
                  if ((bitget_secret_key || "").replace(/\*/g, "") === "") {
                    set_bitget_secret_key(settings?.bitget_secret_key);
                  }
                }}
                size="small"
                label="Secret Key"
                fullWidth
                margin="dense"
              />
              <TextField
                value={bitget_passphrase}
                placeholder={(bitget_passphrase || "").replace(/\*/g, "") === "" ? "**********" : "bitget passphrase"}
                onChange={(e) => {
                  set_bitget_passphrase((e.target.value || "").replace(/\*/g, ""));
                }}
                onFocus={() => {
                  if ((bitget_passphrase || "").replace(/\*/g, "") === "") {
                    set_bitget_passphrase("");
                  }
                }}
                onBlur={() => {
                  if ((bitget_passphrase || "").replace(/\*/g, "") === "") {
                    set_bitget_passphrase(settings?.bitget_passphrase);
                  }
                }}
                size="small"
                label="Passphrase"
                fullWidth
                margin="dense"
              />
              <FormControlLabel disabled control={<Switch checked={bitget_test_mode} onChange={(e) => set_bitget_test_mode(e.target.checked)} />} label="Test Mode" />
            </AccordionDetails>
          </Accordion>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ backgroundColor: "#14161f" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={save_settings}>Save</Button>
      </DialogActions>
    </Dialog>,
  ];
};

export default SettingDrawer;

import React, { useEffect, useState } from "react";
import numeral from "numeral";
import { DataGrid } from "@mui/x-data-grid";
import { green, red, yellow } from "../../../../keys";

const WatchListTable = ({ pair = {}, watchlist_updating = false, set_watchlist_updating = () => {}, exchange = "", max_pair = 15, is_tradable_data = {}, pairwatchlist, remove_pair_in_watchlist = () => {}, set_pair = () => {} }) => {
  const [tablerow, set_tablerow] = useState([]);
  const columns = [
    {
      flex: 1,
      minWidth: 100,
      field: "pair",
      headerName: "Pair",
      renderCell: (props) => {
        const { value } = props;
        const is_tradable = ((is_tradable_data[value] || {})[value] || 0) == 0 ? false : true;
        return [
          <a
            className="button"
            onClick={() => {
              set_pair((prev) => {
                prev[exchange] = value;
                return prev;
              });
            }}
            style={{ backgroundColor: "transparent", color: is_tradable ? yellow : undefined }}
          >
            {value}
          </a>,
        ];
      },
    },
    {
      flex: 1,
      minWidth: 75,
      headerAlign: "center",
      align: "center",
      field: "price_change_percentage",
      headerName: "Change %",
      renderCell: (props) => {
        const { value } = props;
        let color = value < 0 ? red : value > 0 ? green : undefined;
        return [
          <div
            className="centered-component"
            style={{
              color: color,
            }}
          >
            {numeral(value).format("0,0[.]00")} %
          </div>,
        ];
      },
    },
    {
      width: 30,

      headerAlign: "center",
      align: "center",
      field: "id",
      headerName: "",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (props) => {
        const { hasFocus, row, value } = props;
        return [
          <button
            onClick={() => {
              remove_pair_in_watchlist(value);
              const temp = pair[exchange];
              if (temp == row.pair) {
                set_pair((prev) => {
                  prev[exchange] = "";
                  return prev;
                });
              }
            }}
            className="button centered-component"
            style={{ backgroundColor: red, fontSize: 10, color: "white", borderRadius: "50%", width: 20, height: 20 }}
          >
            X
          </button>,
        ];
      },
    },
  ];
  useEffect(() => {
    set_tablerow(pairwatchlist);
  }, [pairwatchlist]);
  return (
    <div style={{ minHeight: 37 * max_pair + 32, height: 37 * max_pair + 32, width: "100%" }}>
      <DataGrid
        loading={watchlist_updating}
        style={{ border: 0, overflow: "hidden" }}
        density="compact"
        slots={{
          noRowsOverlay: () => (
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              <p>Watchlist Empty</p>
            </div>
          ),
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25, page: 0 },
          },
        }}
        hideFooter={tablerow.length > 25 ? false : true}
        className="positiontables borderless-table"
        rows={tablerow}
        columns={columns}
        sx={{ "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": { display: "none" } }}
      />
    </div>
  );
};

export default WatchListTable;

import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import TextField from "@mui/material/TextField";
import numeral from "numeral";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { header_color } from "../../../../keys";
const marks = [
  {
    value: 0,
  },
  {
    value: 25,
  },
  {
    value: 50,
  },
  {
    value: 75,
  },
  {
    value: 100,
  },
];
const iOSBoxShadow = "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));
const BotStategy = ({
  set_active_tab = () => {},
  exchange = "",
  webSocket,
  strategy_pair = "",
  set_strategy_pair = () => {},
  strategy_timeframe = "",
  set_strategy_timeframe = () => {},
  pair = "",
  size = 0,
  set_size = () => {},
  tp_values = {},
  set_tp_values = () => {},
  sl_values = {},
  set_sl_values = () => {},
  selected_strategy,
  set_selected_strategy = () => {},
  account_balance = 0,
  open_strat_selection = false,
  set_open_strat_selection = () => {},
  strategies = [],
  open_position_list = [],
  set_open_position_list = () => {},

  ma_fast = 5,
  set_ma_fast = () => {},
  ma_slow = 20,
  set_ma_slow = () => {},

  ema_fast = 12,
  set_ema_fast = () => {},
  ema_slow = 26,
  set_ema_slow = () => {},
  ema_signal = 9,
  set_ema_signal = () => {},
  rsi_length = 50,
  set_rsi_length = () => {},
  open_additional_params = undefined,
  set_open_additional_params = () => {},
}) => {
  const [dialog_data, set_dialog_data] = useState(undefined);
  const [submitting, set_submitting] = useState(false);

  const initiate_bot = () => {
    const exch = exchange || "";
    const acc_bal = account_balance || 0;
    const strategy_size = size || undefined;
    const strategy = selected_strategy?.id || undefined;
    const pair = strategy_pair || undefined;
    const timeFrame = strategy_timeframe || undefined;
    const tp_value = strategy == "Scalping" ? 1 : tp_values?.value;
    const tp_type = tp_values?.type;
    const sl_value = strategy == "Scalping" ? 1 : sl_values?.value;
    const sl_type = sl_values?.type;

    const ma_f = ma_fast;
    const ma_s = ma_slow;

    const ema_f = ema_fast;
    const ema_s = ema_slow;
    const ema_sig = ema_signal;
    const rsi = rsi_length;
    if (exch === undefined || exch === "") {
      set_dialog_data({
        title: "Required Field",
        msg: "Please select an exchange first.",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
        },
        cb: () => {
          set_dialog_data(undefined);
        },
      });
      return;
    }
    if (strategy_size === undefined || strategy_size === 0) {
      set_dialog_data({
        title: "Required Field",
        msg: "Please enter the Size first.",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
        },
        cb: () => {
          set_dialog_data(undefined);
        },
      });
      return;
    }
    if (strategy === undefined) {
      set_dialog_data({
        title: "Required Field",
        msg: "Please select a strategy first.",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
        },
        cb: () => {
          set_dialog_data(undefined);
        },
      });
      return;
    }
    if (pair === "" || pair === undefined) {
      set_dialog_data({
        title: "Required Field",
        msg: "Please select a pair first.",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
        },
        cb: () => {
          set_dialog_data(undefined);
        },
      });
      return;
    }
    if (timeFrame === "" || timeFrame === undefined) {
      set_dialog_data({
        title: "Required Field",
        msg: "Please select a time frame first.",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
        },
        cb: () => {
          set_dialog_data(undefined);
        },
      });
      return;
    }
    if (tp_value === "" || tp_value === undefined) {
      set_dialog_data({
        title: "Required Field",
        msg: "Please enter the take profit value first.",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
        },
        cb: () => {
          set_dialog_data(undefined);
        },
      });
      return;
    }
    if (tp_value <= 0) {
      set_dialog_data({
        title: "Required Field",
        msg: "Take profit cannot be less than or equal zero(0).",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
        },
        cb: () => {
          set_dialog_data(undefined);
        },
      });
      return;
    }
    if (sl_value === "" || sl_value === undefined) {
      set_dialog_data({
        title: "Required Field",
        msg: "Please enter the stop loss value first.",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
        },
        cb: () => {
          set_dialog_data(undefined);
        },
      });
      return;
    }
    if (sl_value <= 0) {
      set_dialog_data({
        title: "Required Field",
        msg: "Stop loss value cannot be less than or equal zero(0).",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
        },
        cb: () => {
          set_dialog_data(undefined);
        },
      });
      return;
    }
    if (strategy == "Technical") {
      if (ema_f === "" || ema_f === undefined) {
        set_dialog_data({
          title: "Required Field",
          msg: "Please enter the EMA fast value first.",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
          cb: () => {
            set_dialog_data(undefined);
          },
        });
        return;
      }
      if (ema_f <= 0) {
        set_dialog_data({
          title: "Required Field",
          msg: "EMA Fast cannot be less than or equal zero(0).",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
          cb: () => {
            set_dialog_data(undefined);
          },
        });
        return;
      }
      if (ema_s === "" || ema_s === undefined) {
        set_dialog_data({
          title: "Required Field",
          msg: "Please enter the EMA slow value first.",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
          cb: () => {
            set_dialog_data(undefined);
          },
        });
        return;
      }
      if (ema_s <= 0) {
        set_dialog_data({
          title: "Required Field",
          msg: "EMA slow cannot be less than or equal zero(0).",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
          cb: () => {
            set_dialog_data(undefined);
          },
        });
        return;
      }

      if (ema_f >= ema_s) {
        set_dialog_data({
          title: "Invalid Value",
          msg: "EMA fast cannot be greater than or equal to EMA slow.",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
          cb: () => {
            set_dialog_data(undefined);
          },
        });
        return;
      }
      if (ema_s <= ema_f) {
        set_dialog_data({
          title: "Invalid Value",
          msg: "EMA slow cannot be less than or equal to EMA fast.",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
          cb: () => {
            set_dialog_data(undefined);
          },
        });
        return;
      }

      if (ema_sig === "" || ema_sig === undefined) {
        set_dialog_data({
          title: "Required Field",
          msg: "Please enter the EMA signal value first.",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
          cb: () => {
            set_dialog_data(undefined);
          },
        });
        return;
      }
      if (ema_sig <= 0) {
        set_dialog_data({
          title: "Required Field",
          msg: "EMA signal cannot be less than or equal zero(0).",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
          cb: () => {
            set_dialog_data(undefined);
          },
        });
        return;
      }
      if (rsi === "" || rsi === undefined) {
        set_dialog_data({
          title: "Required Field",
          msg: "Please enter the RSI length value first.",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
          cb: () => {
            set_dialog_data(undefined);
          },
        });
        return;
      }
      if (rsi <= 0) {
        set_dialog_data({
          title: "Required Field",
          msg: "RSI length cannot be less than or equal zero(0).",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
          cb: () => {
            set_dialog_data(undefined);
          },
        });
        return;
      }
    }

    if (strategy == "Scalping") {
      if (ma_f === "" || ma_f === undefined) {
        set_dialog_data({
          title: "Required Field",
          msg: "Please enter the MA fast value first.",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
          cb: () => {
            set_dialog_data(undefined);
          },
        });
        return;
      }
      if (ma_f <= 0) {
        set_dialog_data({
          title: "Required Field",
          msg: "MA Fast cannot be less than or equal zero(0).",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
          cb: () => {
            set_dialog_data(undefined);
          },
        });
        return;
      }
      if (ma_f === "" || ma_f === undefined) {
        set_dialog_data({
          title: "Required Field",
          msg: "Please enter the MA slow value first.",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
          cb: () => {
            set_dialog_data(undefined);
          },
        });
        return;
      }
      if (ma_f <= 0) {
        set_dialog_data({
          title: "Required Field",
          msg: "MA slow cannot be less than or equal zero(0).",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
          cb: () => {
            set_dialog_data(undefined);
          },
        });
        return;
      }

      if (ma_f >= ma_s) {
        set_dialog_data({
          title: "Invalid Value",
          msg: "MA fast cannot be greater than or equal to MA slow.",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
          cb: () => {
            set_dialog_data(undefined);
          },
        });
        return;
      }
      if (ma_s <= ma_f) {
        set_dialog_data({
          title: "Invalid Value",
          msg: "MA slow cannot be less than or equal to MA fast.",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
          cb: () => {
            set_dialog_data(undefined);
          },
        });
        return;
      }
    }

    send_strategy(exch, acc_bal, strategy_size, strategy, pair, timeFrame, tp_value, tp_type, sl_value, sl_type, ema_f, ema_s, ema_sig, rsi, ma_f, ma_s);
  };
  const send_strategy = (exchange, account_balance, size, strategy, pair, timeFrame, tp_value, tp_type, sl_value, sl_type, ema_f, ema_s, ema_sig, rsi, ma_f, ma_s) => {
    const datetime = Date.now();
    const id = (exchange + "_" + pair + "_" + datetime).toUpperCase();
    let already_existing =
      open_position_list.filter((row) => {
        return (row.id || "").includes((exchange + "_" + pair).toUpperCase());
      }).length > 0
        ? true
        : false;
    if (already_existing) {
      set_dialog_data({
        title: "Bot Exist",
        msg: "Bot Strategy Already Exist.",
        type: "alert",
        cancel_cb: () => {
          set_dialog_data(undefined);
        },
        cb: () => {
          set_dialog_data(undefined);
        },
      });
    } else {
      if (webSocket.current[exchange]) {
        webSocket.current[exchange].send(
          JSON.stringify({
            datetime,
            type: "initiate_strategy_bot",
            id: id,
            exchange,
            size,
            size_percent: account_balance === 0 ? 0 : numeral((size / account_balance) * 100).format("0[.]00") * 1,
            strategy,
            pair,
            time_frame: timeFrame,
            tp_value,
            tp_type,
            sl_value,
            sl_type,
            ema_fast: ema_f,
            ema_slow: ema_s,
            ema_signal: ema_sig,
            rsi_length: rsi,
            ma_fast: ma_f,
            ma_slow: ma_s,
          })
        );
      }

      let temp = [...open_position_list];
      temp.push({
        id: id,
        opentime: datetime,
        status: "waiting_for_response",
        risk: sl_value,
        reward: tp_value,
        pair: pair,
        exchange: exchange,
        strategy: strategy,
        timeframe: timeFrame,
      });
      set_open_strat_selection(false);
      set_ema_fast(12);
      set_ema_slow(26);
      set_ema_signal(9);
      set_rsi_length(50);
      set_ma_fast(5);
      set_ma_slow(20);
      set_size(0);
      set_tp_values({
        value: 1,
        type: "Mark",
      });
      set_sl_values({
        value: 1,
        type: "Mark",
      });
      set_open_position_list(temp);
    }
  };
  useEffect(() => {
    set_ema_fast(12);
    set_ema_slow(26);
    set_ema_signal(9);
    set_rsi_length(50);
    set_ma_fast(5);
    set_ma_slow(20);
  }, [pair]);

  return [
    <Dialog
      open={dialog_data !== undefined}
      onClose={
        dialog_data?.cancel_cb
          ? dialog_data?.cancel_cb
          : () => {
              set_dialog_data(undefined);
            }
      }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialog_data?.title || ""}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialog_data?.msg || ""}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={
            dialog_data?.cancel_cb
              ? dialog_data?.cancel_cb
              : () => {
                  set_dialog_data(undefined);
                }
          }
          autoFocus
        >
          {dialog_data?.type === "confirmation" ? "Go Back" : "Close"}
        </Button>
        {dialog_data?.type === "confirmation" ? (
          <Button
            onClick={() => {
              set_dialog_data(undefined);
              dialog_data?.cb();
            }}
          >
            Leave
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>,

    <div style={{ width: "100%" }}>
      {/* <div style={{ padding: 12, backgroundColor: header_color }}>BOT STRATEGY</div> */}
      <div style={{ padding: 12 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <button
            onClick={() => {
              let opened = open_strat_selection;
              set_open_strat_selection((prev) => !prev);
            }}
            className="button"
            style={{ backgroundColor: "white", border: 0, padding: 0, borderRadius: "50%", width: 24, height: 24, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          >
            {open_strat_selection ? <ArrowForwardIosIcon fontSize="8" /> : <ArrowBackIosNewIcon fontSize="8" />}
          </button>
          <div style={{ marginLeft: 8, width: "100%" }}>
            {selected_strategy ? (
              <React.Fragment>
                <div>
                  {selected_strategy.name}
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div color="inherit">{selected_strategy.tooltip}</div>
                      </React.Fragment>
                    }
                  >
                    <HelpIcon fontSize="" style={{ marginLeft: 4, marginTop: -4 }} />
                  </HtmlTooltip>
                </div>
                <div style={{ marginBottom: 8 }}></div>
                <div>{strategy_pair}</div>
                <div>{strategy_timeframe}</div>
                <div style={{ marginBottom: 8 }}></div>
              </React.Fragment>
            ) : (
              <div style={{ color: "#ccc", height: 100 }}>Select a Strategy</div>
            )}
          </div>
        </div>

        <div>
          <div style={{ backgroundColor: "#1e2329", borderRadius: 4, padding: 12 }}>
            <div style={{ fontSize: 12 }}>
              <span style={{ color: "#ccc" }}>Avbl: </span>
              <span>{numeral(account_balance).format("0,0[.]00")} USDT</span>
            </div>
            <div style={{ marginBottom: 4 }}></div>
            <div>
              <TextField
                disabled={selected_strategy === undefined}
                inputProps={{
                  min: 0,
                  max: account_balance,
                  className: "hidden_number_arrow",
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{numeral((size / account_balance) * 100).format("0[.]00")}%</InputAdornment>,
                }}
                style={{ width: "100%" }}
                type="number"
                placeholder="Size"
                size="small"
                variant="outlined"
                value={size}
                onChange={(e) => {
                  let value = e.target.value;

                  value = value.replace(/-/g, "").replace(/e/g, "");

                  let val = numeral(parseFloat(value)).format("0[.]00") * 1;
                  if (val < 0) {
                    val = 0;
                  }
                  if (val * 1 > account_balance * 1) {
                    val = account_balance;
                  }
                  set_size(val * 1);
                }}
              />
            </div>
            <div style={{ marginBottom: 8 }}></div>
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
              <IOSSlider
                disabled={selected_strategy === undefined}
                aria-label="ios slider"
                value={parseInt((size / account_balance) * 100)}
                marks={marks}
                min={0}
                max={100}
                onChange={(e, value) => {
                  let parsedValue = value === 0 ? 0 : account_balance * (value / 100);

                  let val = numeral(parseFloat(parsedValue)).format("0[.]00") * 1;
                  if (val < 0) {
                    val = 0;
                  }
                  set_size(val * 1);
                }}
              />
            </div>
          </div>

          <div style={{ marginBottom: 16 }}></div>
          <div style={{ backgroundColor: "#1e2329", borderRadius: 4, padding: 12 }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
              <label style={{ width: "25%", fontSize: 14 }}>Reward (TP)</label>
              <TextField
                disabled={selected_strategy === undefined || selected_strategy?.id == "Scalping"}
                type="number"
                style={{ maxWidth: 75 }}
                inputProps={{
                  style: { textAlign: "center" },
                }}
                size="small"
                variant="outlined"
                value={selected_strategy?.id == "Scalping" ? 1 : tp_values.value}
                onChange={(e) => {
                  let temp = { ...tp_values };
                  let value = e.target.value;

                  value = value.replace(/-/g, "").replace(/e/g, "");

                  let val = numeral(parseFloat(value)).format("0[.]0000") * 1;
                  if (val < 0) {
                    val = 0;
                  }
                  temp.value = val * 1;
                  set_tp_values(temp);
                }}
              />
              <TextField
                disabled={selected_strategy === undefined || selected_strategy?.id == "Scalping"}
                value={tp_values.type}
                onChange={(e) => {
                  let temp = { ...tp_values };
                  temp.type = e.target.value;
                  set_tp_values(temp);
                }}
                style={{ width: "40%", maxWidth: 100 }}
                size="small"
                select
                defaultValue="Mark"
                className="sm-font-dropdown"
              >
                {["Mark", "Last"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div style={{ marginBottom: 8 }}></div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
              <label style={{ width: "25%", fontSize: 14 }}>Risk (SL)</label>
              <TextField
                disabled={selected_strategy === undefined || selected_strategy?.id == "Scalping"}
                type="number"
                style={{ maxWidth: 75 }}
                inputProps={{
                  style: { textAlign: "center" },
                }}
                size="small"
                variant="outlined"
                value={selected_strategy?.id == "Scalping" ? 1 : sl_values.value}
                onChange={(e) => {
                  let temp = { ...sl_values };
                  let value = e.target.value;
                  value = value.replace(/-/g, "").replace(/e/g, "");
                  let val = numeral(parseFloat(value)).format("0[.]0000") * 1;
                  if (val < 0) {
                    val = 0;
                  }
                  temp.value = val * 1;
                  set_sl_values(temp);
                }}
              />
              <TextField
                disabled={selected_strategy === undefined || selected_strategy?.id == "Scalping"}
                style={{ width: "40%", maxWidth: 100 }}
                size="small"
                select
                defaultValue="Mark"
                value={sl_values.type}
                onChange={(e) => {
                  let temp = { ...sl_values };
                  temp.type = e.target.value;
                  set_sl_values(temp);
                }}
                className="sm-font-dropdown"
              >
                {["Mark", "Last"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>

          <div style={{ marginBottom: 32 }}></div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            <button
              disabled={selected_strategy === undefined}
              onClick={() => {
                initiate_bot();
              }}
              className="button"
              style={{ padding: 8, color: "white", borderRadius: 4, backgroundColor: selected_strategy === undefined ? "#343739" : "#46b860" }}
            >
              RUN BOT
            </button>
          </div>
          <div style={{ marginBottom: 4 + 16 }}></div>
        </div>
      </div>
    </div>,
  ];
};

export default BotStategy;

import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { header_border_color, header_color } from "../../keys";
const ExchangeList = ({ screenwidth, exchange, set_exchange, exchange_list, set_exchange_list, set_open_user_settings = () => {} }) => {
  const onChange = (event, value) => {
    set_exchange(value);
  };
  const exchange_btn = (
    <button
      onClick={() => {
        set_open_user_settings(true);
      }}
      className="button"
      style={{ flexGrow: 0, backgroundColor: "transparent", color: "white", border: 0, alignSelf: "center" }}
    >
      <AddCircleIcon />
    </button>
  );
  return [
    <Paper
      sx={{
        display: "flex",
        border: (theme) => `1px solid ${header_border_color}`,
        flexWrap: "wrap",
        borderRadius: 0,
        alignItems: "center",
        backgroundColor: header_color,
        backgroundImage: "none",
        marginBottom: "16px",
      }}
    >
      {screenwidth < 600 ? (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
          <div style={{ padding: 6, paddingLeft: 12, paddingRight: 12, fontSize: "0.875rem" }}>EXCHANGE:</div>
          {exchange_btn}
        </div>
      ) : (
        <div style={{ padding: 6, paddingLeft: 12, paddingRight: 12, fontSize: "0.875rem" }}>EXCHANGE:</div>
      )}

      <div style={{ flexGrow: 1, maxWidth: "100%" }}>
        <Box className="ExchangeListTabs">
          <Tabs variant="scrollable" scrollButtons={"auto"} allowScrollButtonsMobile value={exchange} onChange={onChange} aria-label="AnalyticsTabs">
            {exchange_list.map((exchange, index) => {
              return [<Tab className="dense-tabs" label={exchange.toUpperCase()} value={exchange} id={`${exchange}Tab`} />];
            })}
          </Tabs>
        </Box>
        {/* <ToggleGroup value={exchange} uppercase_value={true} onChange={onChange} options={exchange_list} /> */}
      </div>
      {screenwidth < 600 ? null : exchange_btn}
    </Paper>,
  ];
};

export default ExchangeList;

import CircularProgress from "@mui/material/CircularProgress";
import { api_url, component_bg_color, minute_ms, session_valid_for } from "../../../keys";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate, useLocation } from "react-router-dom";
const SessionChecker = ({ return_data = (data) => {} }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [checking, set_checking] = useState(true);
  const handleOnIdle = async (event) => {
    redirect_to_login();
  };

  const handleOnActive = (event) => {};

  const handleOnAction = (e) => {};

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  const redirect_to_login = () => {
    sessionStorage.removeItem("user_data");
    sessionStorage.removeItem("session_timeout");
    if (location.pathname === "/login") {
      return;
    }
    navigate("/login", {
      state: {
        test_params: "test_params",
      },
    });
  };
  useEffect(() => {
    if (checking) {
      let user_data = sessionStorage.getItem("user_data");
      if (user_data === null || user_data === "") {
        user_data = undefined;
      }
      user_data = user_data ? JSON.parse(user_data) : {};
      const session_timeout = parseInt(sessionStorage.getItem("session_timeout")) || new Date("2023/06/15");
      //check if session exist
      // if (user_data.login_token === "" || user_data.login_token === null || user_data.login_token === undefined) {
      //   redirect_to_login();
      //   set_checking(false);
      //   return;
      // }

      if (session_timeout === "" || session_timeout === null || session_timeout === undefined) {
        redirect_to_login();
        set_checking(false);
        return;
      }
      const current_dt = Date.now();

      //check if session timeout is reached
      const timeSpan = 1000 * 60 * 60 * 24 * session_valid_for;
      if (current_dt - session_timeout >= timeSpan) {
        redirect_to_login();
        set_checking(false);
        return;
      }
      return_data(user_data);
      set_checking(false);
      //check if account exist
      // const headers = {
      //   "Content-Type": "application/json",
      // };
      // axios
      //   .post(
      //     api_url + "/check_account/",
      //     { login_token: user_data.login_token, username: user_data.username },
      //     {
      //       headers: headers,
      //     }
      //   )
      //   .then((res) => {
      //     if (res.data.account_status === "OK") {
      //       //continue
      //       return_data(user_data);
      //       set_checking(false);
      //     } else {
      //       redirect_to_login();
      //     }
      //   })
      //   .catch((err) => {
      //     redirect_to_login();
      //   });
    }
  }, [checking]);

  useEffect(() => {
    const interval = setInterval(() => {
      let user_data = sessionStorage.getItem("user_data");
      if (user_data === null || user_data === "") {
        user_data = undefined;
      }
      user_data = user_data ? JSON.parse(user_data) : {};
      if (user_data.login_token === "" || user_data.login_token === null || user_data.login_token === undefined) {
        sessionStorage.setItem("session_timeout", Date.now());
      }
    }, minute_ms * 30);
    return () => clearInterval(interval);
  }, []);

  return checking
    ? [
        <div
          style={{
            zIndex: 9999,
            backgroundColor: component_bg_color,
            opacity: 0.9,
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>,
      ]
    : [];
};

export default SessionChecker;

import React, { useState, useEffect } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { channel_ip, channel_port } from "../../keys";
let update_ui_id = Date.now();
const ChannelManager = ({ exchange = "", user_data = {}, receiver = () => {}, webSocket }) => {
  // eslint-disable-next-line
  const [ws_list, set_ws_list] = useState([]);
  const [room, set_room] = useState(undefined);
  const [new_data, set_new_data] = useState(undefined);
  useEffect(() => {
    let loop = undefined;
    if (room !== undefined && webSocket.current[room["exchange"]] == undefined) {
      webSocket.current[room["exchange"]] = new W3CWebSocket(`${channel_ip}/ws/` + room["room"] + "/");
      webSocket.current[room["exchange"]].onopen = () => {
        console.log("WebSocket Client Connected");
        if (room["exchange"] == "general") {
        } else {
          setTimeout(() => {
            webSocket.current[room["exchange"]].send(
              JSON.stringify({
                type: "update_ui",
                id: update_ui_id,
              })
            );
          }, 5000);
        }
      };
      webSocket.current[room["exchange"]].onclose = (e) => {
        console.log(room["room"], "echo-protocol Client Closed", e);
        set_new_data({
          type: "watchlist_update",
          data: [],
          exchange: room["exchange"],
        });
      };
      webSocket.current[room["exchange"]].onerror = (e) => {
        console.log(room["room"], "Connection Error", e);
        set_new_data({
          type: "watchlist_update",
          data: [],
          exchange: room["exchange"],
        });
      };
      webSocket.current[room["exchange"]].onmessage = (message) => {
        const websocketdata = message.data;
        let data = JSON.parse(websocketdata);
        if (data.type == "update_ui_bounce") {
          if (data.type == "update_ui_bounce" && update_ui_id != data.id) return;
          loop = setTimeout(() => {
            try {
              webSocket.current[room["exchange"]].send(
                JSON.stringify({
                  type: "update_ui",
                  id: update_ui_id,
                })
              );
            } catch (e) {
              console.log("send error", e);
            }
          }, 1000 * 2);
        } else {
          if (data.type == "contract_list") {
            setTimeout(() => {
              set_new_data({
                ...data,
                exchange: room["exchange"],
              });
            }, 500);
          } else if (data.type == "account_balance") {
            setTimeout(() => {
              set_new_data({
                ...data,
                exchange: room["exchange"],
              });
            }, 1000);
          } else {
            set_new_data({
              ...data,
              exchange: room["exchange"],
            });
          }
        }
      };
    } else {
      if (room && room["exchange"]) {
        webSocket.current[room["exchange"]].send(
          JSON.stringify({
            type: "reconnect",
            exchange: room["exchange"],
            id: update_ui_id,
          })
        );
        setTimeout(() => {
          webSocket.current[room["exchange"]].send(
            JSON.stringify({
              type: "update_ui",
              id: update_ui_id,
            })
          );
        }, 5000);
      }
    }

    return () => {
      if (loop) clearTimeout(loop);
      // webSocket?.current[room?.prev_room || ""]?.close();
    };
  }, [room]);

  useEffect(() => {
    if (new_data) {
      receiver(new_data);
      set_new_data(undefined);
    }
  }, [new_data]);
  // const send = (value) => {
  //   webSocket.current.send(
  //     JSON.stringify({
  //       type: "message",
  //       text: value,
  //       sender: "Test_User",
  //     })
  //   );
  // };
  useEffect(() => {
    if ((user_data?.username || "") !== "")
      set_room((prev) => {
        return {
          prev_room: prev?.exchange || "",
          room: user_data.username + "_" + (exchange == "" ? "general" : exchange),
          exchange: exchange == "" ? "general" : exchange,
          username: user_data.username,
        };
      });
  }, [exchange, user_data]);

  return [<React.Fragment key="channel-manager-fragment"></React.Fragment>];
};

export default ChannelManager;

import React, { useEffect, useState } from "react";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import numeral from "numeral";
import axios from "axios";
import { api_url, green, red } from "../../../keys";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
const columns = [
  {
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    field: "date",
    headerName: "Month",
    renderCell: (props) => {
      const { hasFocus, value } = props;
      return [moment(value).format("YYYY MMMM")];
    },
  },
  {
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    field: "total_amount",
    headerName: "Investment",
    renderCell: (props) => {
      const { hasFocus, value, row } = props;

      return <span>{numeral(value).format("0,0[.][00]")}</span>;
    },
  },
  {
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    field: "net_pnl",
    headerName: "Net PnL",
    valueGetter: (props) => {
      const { hasFocus, value, row } = props;
      let final_value = value;
      final_value = row.total_amount + row.total_pnl;
      return final_value;
    },
    renderCell: (props) => {
      const { hasFocus, value, row } = props;
      let final_value = value;
      let gain = row.total_pnl > 0;
      final_value = row.total_amount + row.total_pnl;

      return (
        <span style={{ color: final_value === 0 ? undefined : gain ? green : red }}>
          {gain ? "+" : "-"}
          {numeral(final_value).format("0,0[.][00]")}
        </span>
      );
    },
  },

  {
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    field: "total_pnl",
    headerName: "ROE",
    valueGetter: (props) => {
      const { hasFocus, value, row } = props;
      let final_value = value;
      final_value = row.total_pnl;
      return final_value;
    },
    renderCell: (props) => {
      const { hasFocus, value, row } = props;
      let final_value = value;
      final_value = row.total_pnl;
      let gain = row.total_pnl > 0;
      return (
        <span style={{ color: final_value === 0 ? undefined : gain ? green : red }}>
          {gain ? "+" : ""}
          {numeral(final_value).format("0,0[.][00]")}
        </span>
      );
    },
  },
  {
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    field: "percentage",
    headerName: "Percentage",
    valueGetter: (props) => {
      const { hasFocus, value, row } = props;
      let final_value = value;
      final_value = row.total_pnl == 0 || row.total_amount == 0 ? 0 : (row.total_pnl / row.total_amount) * 100;
      return final_value;
    },
    renderCell: (props) => {
      const { hasFocus, value, row } = props;
      let final_value = value;
      final_value = row.total_pnl == 0 || row.total_amount == 0 ? 0 : (row.total_pnl / row.total_amount) * 100;
      let gain = row.total_pnl > 0;
      return (
        <span style={{ color: final_value === 0 ? undefined : gain ? green : red }}>
          {gain ? "+" : ""}
          {numeral(final_value).format("0,0[.][00]")}%
        </span>
      );
    },
  },
];
const PNLMonthlyTable = ({ total_pl = 0, set_total_pl = () => {}, total_investment = 0, set_total_investment = () => {}, exchange = "All", loading = false, set_loading = () => {} }) => {
  const [dialog_data, set_dialog_data] = useState(undefined);
  const [fetching_data, set_fetching_data] = useState(false);

  const get_analytics_data = (exchange) => {
    set_fetching_data(true);
    let user_data = sessionStorage.getItem("user_data");
    if (user_data === null || user_data === "") {
      user_data = undefined;
    }
    user_data = user_data ? JSON.parse(user_data) : {};
    let page_size = 10;
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        api_url + "/get_pnl/",
        {
          login_token: user_data.login_token,
          username: user_data.username,
          exchange: exchange === "All" ? undefined : exchange,
          type: "month",
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          //continue
          successful_saving(res?.data?.data);
          set_fetching_data(false);
        } else {
          console.log("error?", res.data);
          set_fetching_data(false);
          set_dialog_data({
            title: "Request Error",
            msg: "Something went wrong, Please try again later.",
            type: "alert",
            cancel_cb: () => {
              set_dialog_data(undefined);
            },
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        set_fetching_data(false);
        set_dialog_data({
          title: "Request Error",
          msg: "Something went wrong, Please try again later.",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
        });
      });
  };
  const successful_saving = (data) => {
    set_fetching_data(false);
    return_data(data);
  };
  // const [page_index, set_page_index] = useState(1);
  // const [num_page, set_num_page] = useState(1);
  // const [has_next, set_has_next] = useState(false);
  // const [has_previous, set_has_previous] = useState(false);
  const [pnl_data, set_pnl_data] = useState([]);
  const return_data = (data) => {
    if (data) {
      set_pnl_data(data);
      let t_investment = 0;
      let t_pl = 0;
      for (let i = 0; i < data.length; i++) {
        const row = data[i];
        t_investment += row.total_amount;
        let final_value = row.total_amount + row.total_pnl;
        t_pl += final_value;
      }
      set_total_investment(t_investment);
      set_total_pl(t_pl);
    }
  };
  useEffect(() => {
    if (exchange) {
      get_analytics_data(exchange);
    }

    return () => set_pnl_data([]);
  }, [exchange]);
  return [
    <Dialog
      open={dialog_data !== undefined}
      onClose={
        dialog_data?.cancel_cb
          ? dialog_data?.cancel_cb
          : () => {
              set_dialog_data(undefined);
            }
      }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialog_data?.title || ""}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialog_data?.msg || ""}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={
            dialog_data?.cancel_cb
              ? dialog_data?.cancel_cb
              : () => {
                  set_dialog_data(undefined);
                }
          }
          autoFocus
        >
          {dialog_data?.type === "confirmation" ? "Go Back" : "Close"}
        </Button>
        {dialog_data?.type === "confirmation" ? (
          <Button
            onClick={() => {
              set_dialog_data(undefined);
              dialog_data?.cb();
            }}
          >
            Leave
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>,
    <div style={{ height: pnl_data.length === 0 ? 200 : pnl_data.length >= 5 ? 350 : undefined, width: "fit-content" }}>
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        hideFooter={pnl_data.length > 10 ? false : true}
        className="positiontables"
        rows={pnl_data}
        columns={columns}
      />
    </div>,
  ];
};
export default PNLMonthlyTable;

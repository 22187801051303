import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { api_url, green, red } from "../../../keys";
import numeral from "numeral";
import moment from "moment/moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";

const CloseTable = ({ start_date = undefined, end_date = undefined, hide_columns = [], additional_header }) => {
  const columns = [
    { flex: 1, minWidth: 100, headerAlign: "center", align: "center", field: "exchange", headerName: "Exchange" },
    { flex: 1, minWidth: 100, headerAlign: "center", align: "center", field: "strategy", headerName: "Bot Strategy" },
    {
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      field: "amount",
      headerName: "Amount",
      sortable: false,
      renderCell: (props) => {
        const { hasFocus, value } = props;
        let val = (value || 0) * 1;
        return [numeral(val).format("0,0[.][0000]")];
      },
    },
    { flex: 1, minWidth: 100, headerAlign: "center", align: "center", field: "timeframe", headerName: "Timeframe" },
    { flex: 1, minWidth: 100, headerAlign: "center", align: "center", field: "pair", headerName: "Pair" },
    { flex: 1, minWidth: 100, headerAlign: "center", align: "center", field: "position", sortable: false, headerName: "Action" },
    {
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      field: "entry_price",
      headerName: "Entry",
      sortable: false,
      renderCell: (props) => {
        const { hasFocus, value } = props;
        let val = (value || 0) * 1;
        return [numeral(val).format("0,0[.][0000]")];
      },
    },
    {
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      field: "tp",
      headerName: "Take Profit",
      sortable: false,
      renderCell: (props) => {
        const { hasFocus, value } = props;
        let val = (value || 0) * 1;
        return [numeral(val).format(val < 100 ? "0,0[.][00000]" : "0,0[.][00]")];
      },
    },
    {
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      field: "sl",
      headerName: "Stop Loss",
      sortable: false,
      renderCell: (props) => {
        const { hasFocus, value } = props;
        let val = (value || 0) * 1;
        return [numeral(val).format(val < 100 ? "0,0[.][00000]" : "0,0[.][00]")];
      },
    },
    { flex: 1, minWidth: 100, headerAlign: "center", align: "center", field: "close_price", headerName: "Exit", width: 75 },
    {
      flex: 1,
      minWidth: 75,
      headerAlign: "center",
      align: "center",
      field: "tp_pct",
      headerName: "Reward",
      sortable: false,
      width: 75,
      renderCell: (props) => {
        const { hasFocus, value } = props;
        let val = (value || 0) * 1;
        return [numeral(val).format("0,0.[000000]")];
      },
    },
    {
      flex: 1,
      minWidth: 75,
      headerAlign: "center",
      align: "center",
      field: "sl_pct",
      headerName: "Risk",
      sortable: false,
      renderCell: (props) => {
        const { hasFocus, value } = props;
        let val = (value || 0) * 1;
        return [numeral(val).format("0,0.[000000]")];
      },
    },
    {
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      field: "close_time",
      sortable: false,
      headerName: "Close Time",
      renderCell: (props) => {
        const { hasFocus, value } = props;
        return [moment(value).format("MM/DD/YYYY hh:mm A")];
      },
    },
    {
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      field: "pnl",
      headerName: "PnL",
      sortable: false,
      renderCell: (props) => {
        const { hasFocus, value, row } = props;
        let pnl = (value || 0) * 1;
        return [
          <div style={{ textAlign: "center", color: pnl < 0 ? red : pnl > 0 ? green : undefined }}>
            <div>{numeral(pnl).format("0,0[.][00]") + " USDT "}</div>
            <div>({numeral(row.pnl_pct).format("0,0[.][00]")} %)</div>
          </div>,
        ];
      },
    },
    {
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      field: "closed_by",
      sortable: false,
      headerName: "Close Trigger",
    },
  ].filter((row) => {
    if (hide_columns.includes(row.field)) {
      return false;
    }
    return true;
  });
  const [fetching_data, set_fetching_data] = useState(false);
  const [dialog_data, set_dialog_data] = useState(undefined);
  const get_closed_positions = (page_index = 0) => {
    let user_data = sessionStorage.getItem("user_data");
    if (user_data === null || user_data === "") {
      user_data = undefined;
    }
    user_data = user_data ? JSON.parse(user_data) : {};
    let page_size = 10;
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        api_url + "/get_closed_position/",
        {
          login_token: user_data.login_token,
          username: user_data.username,
          page_index: page_index * 1,
          page_size,
          start_date,
          end_date,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          //continue
          successful_saving(res?.data?.data);
          set_fetching_data(false);
        } else {
          console.log("error?", res.data);
          set_fetching_data(false);
          set_dialog_data({
            title: "Request Error",
            msg: "Something went wrong, Please try again later.",
            type: "alert",
            cancel_cb: () => {
              set_dialog_data(undefined);
            },
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        set_fetching_data(false);
        set_dialog_data({
          title: "Request Error",
          msg: "Something went wrong, Please try again later.",
          type: "alert",
          cancel_cb: () => {
            set_dialog_data(undefined);
          },
        });
      });
  };
  const successful_saving = (data) => {
    set_fetching_data(false);
    return_data(data);
  };
  // const [page_index, set_page_index] = useState(1);
  // const [num_page, set_num_page] = useState(1);
  // const [has_next, set_has_next] = useState(false);
  // const [has_previous, set_has_previous] = useState(false);
  const [closed_table_data, set_closed_table_data] = useState(undefined);
  const return_data = (data) => {
    if (data) {
      set_closed_table_data(data);
    }
  };
  useEffect(() => {
    get_closed_positions();
    return () => set_closed_table_data([]);
  }, []);
  const table_ref = useRef(null);
  return [
    <Dialog
      open={dialog_data !== undefined}
      onClose={
        dialog_data?.cancel_cb
          ? dialog_data?.cancel_cb
          : () => {
              set_dialog_data(undefined);
            }
      }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialog_data?.title || ""}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialog_data?.msg || ""}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={
            dialog_data?.cancel_cb
              ? dialog_data?.cancel_cb
              : () => {
                  set_dialog_data(undefined);
                }
          }
          autoFocus
        >
          {dialog_data?.type === "confirmation" ? "Go Back" : "Close"}
        </Button>
        {dialog_data?.type === "confirmation" ? (
          <Button
            onClick={() => {
              set_dialog_data(undefined);
              dialog_data?.cb();
            }}
          >
            Leave
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>,
    additional_header ? additional_header : null,
    <div style={{ height: (closed_table_data?.count || 0) >= 5 ? 350 : (closed_table_data?.count || 0) === 0 ? 200 : undefined, width: "100%" }} id="closed_position_table">
      <DataGrid
        style={additional_header ? { borderTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0 } : undefined}
        ref={table_ref}
        rowsPerPageOptions={[5, 10, 20]}
        // pagination={
        //   closed_table_data
        //     ? {
        //         paginationModel: {
        //           pageSize: closed_table_data?.item_per_row,
        //           page: (close_position_list?.page_index || 1) - 1,
        //         },
        //       }
        //     : false
        // }
        // pageSize={closed_table_data?.item_per_row}
        // rowCount={closed_table_data?.count}
        // onPaginationModelChange={({ pageSize, page }) => {
        //   console.log(pageSize, page);
        // }}
        pagination={false}
        // paginationModel={{
        //   pageSize: closed_table_data?.item_per_row,
        //   page: (close_position_list?.page_index || 1) - 1,
        //   count: closed_table_data?.num_pages,
        // }}
        // onPaginationModelChange={({ pageSize, page }) => {
        //   console.log("page", page);
        //   get_closed_positions(pageSize, page);
        // }}
        // pageSizeOptions={[10, 25, 50]}
        loading={fetching_data}
        hideFooter={true}
        className="positiontables"
        rows={closed_table_data?.list || []}
        columns={columns}
      />
      {/* closed_table_data?.has_other_page ? false : true */}
    </div>,
    <div>
      {closed_table_data?.has_other_page ? (
        <div style={{ padding: 8, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
          <Pagination
            disabled={fetching_data}
            onChange={(e, value) => {
              get_closed_positions(value);
              if (table_ref?.current) {
                document.querySelector("#closed_position_table .MuiDataGrid-virtualScroller").scrollTop = 0;
              }
            }}
            page={closed_table_data?.page_index || 1}
            count={closed_table_data?.num_pages}
            showFirstButton
            showLastButton
          />
        </div>
      ) : null}
    </div>,
  ];
};

export default CloseTable;

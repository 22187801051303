import React, { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Refresh from "@mui/icons-material/Refresh";
import WatchListTable from "./WatchListTable";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { header_color } from "../../../../keys";
const max_pair = 15;
const WatchList = ({ watchlist_updating = false, set_watchlist_updating = () => {}, is_tradable_data = {}, webSocket, pair_list = [], pair = "", set_pair = () => {}, exchange = "", pairwatchlist = [], set_pairwatchlist = () => {} }) => {
  const [dialog_data, set_dialog_data] = useState(undefined);
  const [add_new_pair, set_add_new_pair] = useState(false);
  const [dialog_pair_name, set_dialog_pair_name] = useState("");
  const [disable_refresh_btn, set_disable_refresh_btn] = useState(false);
  const close_new_pair_dialog = () => {
    set_add_new_pair(false);
    set_dialog_pair_name("");
  };
  const fetch_watchlist = () => {
    set_disable_refresh_btn(true);
    set_pairwatchlist([]);
    if (webSocket.current[exchange]) {
      webSocket.current[exchange].send(
        JSON.stringify({
          type: "watchlist_update",
        })
      );
    }
    setTimeout(() => {
      set_disable_refresh_btn(false);
    }, 2000);
  };
  const add_pair_to_watchlist = () => {
    const temp = [...pairwatchlist];
    let already_existing =
      pairwatchlist.filter((row) => {
        return row.pair == dialog_pair_name;
      }).length > 0
        ? true
        : false;
    if (pairwatchlist.length >= max_pair) {
      set_dialog_data({
        title: "Maximum Pair Reached",
        msg: "You've reached the maximum number of pairs in the watchlist.",
      });
    } else if (already_existing) {
      close_new_pair_dialog();
    } else if (pair_list.includes(dialog_pair_name)) {
      temp.push({
        id: dialog_pair_name,
        pair: dialog_pair_name,
        price_change_percentage: 0,
      });
      set_pairwatchlist(temp);
      if (webSocket.current[exchange]) {
        webSocket.current[exchange].send(
          JSON.stringify({
            type: "watchlist_new",
            pair: dialog_pair_name,
          })
        );
      }
      close_new_pair_dialog();
    } else {
      set_dialog_data({
        title: "Pair Mismatch",
        msg: "Given pair not found in the list of pairs in the exchange.",
      });
    }
  };
  const remove_pair_in_watchlist = (pair_name) => {
    let temp = [...pairwatchlist];
    temp = temp.filter((value) => value.pair !== pair_name);
    set_pairwatchlist(temp);
    if (webSocket.current[exchange]) {
      webSocket.current[exchange].send(
        JSON.stringify({
          type: "watchlist_remove",
          pair: pair_name,
        })
      );
    }
  };

  return [
    <Dialog
      open={dialog_data !== undefined}
      onClose={() => {
        set_dialog_data(undefined);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{dialog_data?.title || ""}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialog_data?.msg || ""}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            set_dialog_data(undefined);
          }}
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>,
    <div style={{ width: "100%" }}>
      <Dialog open={add_new_pair} onClose={close_new_pair_dialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Add new pair</DialogTitle>
        <DialogContent style={{ paddingTop: 8 }}>
          <Autocomplete
            style={{ width: 300, maxWidth: 300 }}
            options={pair_list}
            onChange={(e, value) => {
              set_dialog_pair_name(value);
            }}
            value={dialog_pair_name}
            renderInput={(params) => <TextField {...params} label="Pair name.." />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={add_pair_to_watchlist} autoFocus>
            Add Pair
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ padding: 12, paddingBottom: 0, backgroundColor: header_color, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        {/* WATCHLIST */}
        <button
          disabled={pairwatchlist.length >= max_pair}
          onClick={() => {
            set_add_new_pair(true);
          }}
          className="button"
          style={{
            ...{
              width: "50%",
              marginRight: 12,
              padding: 6,
              backgroundColor: "transparent",
              color: "#90caf9",
              border: "1px solid #90caf9",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            ...(pairwatchlist.length >= max_pair ? { borderColor: "#ccc", color: "#ccc" } : {}),
          }}
        >
          <AddCircleIcon /> <p style={{ marginTop: 0, marginBottom: 0, marginLeft: "10%" }}>Add pair</p>
        </button>
        <button
          disabled={disable_refresh_btn}
          onClick={() => {
            fetch_watchlist();
          }}
          className="button"
          style={{
            ...{
              width: "50%",
              marginLeft: 12,
              padding: 6,
              backgroundColor: "transparent",
              color: "#90caf9",
              border: "1px solid #90caf9",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            ...(disable_refresh_btn ? { borderColor: "#ccc", color: "#ccc" } : {}),
          }}
        >
          <Refresh />
        </button>
      </div>
      <div style={{}}>
        <WatchListTable
          watchlist_updating={watchlist_updating}
          set_watchlist_updating={set_watchlist_updating}
          exchange={exchange}
          max_pair={max_pair}
          is_tradable_data={is_tradable_data}
          pairwatchlist={pairwatchlist}
          set_pair={set_pair}
          pair={pair}
          remove_pair_in_watchlist={remove_pair_in_watchlist}
        />
      </div>
    </div>,
  ];
};

export default WatchList;
